import React from 'react';

import Moment from 'react-moment';

function ParlayFriends({ ticket }) {

    //Variables to print Data easier and with more information
    let textSpread = ticket.spread;
    let team = ticket.team;
     
    if (ticket.type == "Spread"){
        if (Math.sign(textSpread) == 1){textSpread = `+${textSpread}`}
    }

    if (ticket.type == "Over"){
        textSpread = `O${textSpread}`
        team = '';
    }

    if (ticket.type == "Under"){
        textSpread = `U${textSpread}`;
        team = '';
    }
    

    return (
        <>
            <div key={ticket.betId} className="row m-0 parlayFriendDiv">
                <div className="col-12 parlayFriendBorder">
                    <div className="row">
                        <div className="col-12 parlayFriendTextGame">{ticket.game}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-7 parlayFriendTextTeam">{team} | {textSpread}</div>
                        <div className="col-md-5">
                            <Moment className="parlayFriendTextDate" format="M/DD h:mm a">{ticket.date}</Moment>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParlayFriends;