import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ONE_USER } from '../../utils/queries';


import Auth from '../../utils/auth';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { STORE_WALLET_VALUE, UPDATE_USER, REVOKE_TOKENS, STORE_PUB_KEY } from '../../utils/mutations';
import axios from 'axios';

const bcrypt = require('bcryptjs');
const web3 = require('@solana/web3.js');

function Settings() {

    // Use states for conditional rendering. Display when selected
    const [mainNav, setMainNav] = useState(true);
    const [openEmail, setOpenEmail] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [openProfilePic, setOpenProfilePic] = useState(false);
    const [openCoinbase, setOpenCoinbase] = useState(false);
    const [emailFormState, setEmailFormState] = useState({
        email: '',
        emailCheck: ''
    });
    const [passwordFormState, setPasswordFormState] = useState({
        existingPassword: '',
        password: '',
        passwordCheck: ''
    });
    const [infoFormState, setInfoFormState] = useState({
        firstName: '',
        lastName: ''
    })
    const [direct, setDirect] = useState(false);

    //Grabbing Parameters & Querying a User
    const { userId } = useParams();
    const { loading, data, refetch } = useQuery(GET_ONE_USER, {
        variables: { id: userId }
    });

    //useMutations
    const [updateUser] = useMutation(UPDATE_USER);
    const [storeWalletValue] = useMutation(STORE_WALLET_VALUE);
    const [revokeTokens] = useMutation(REVOKE_TOKENS);
    const [storePubKey] = useMutation(STORE_PUB_KEY);



    if (loading) { return (<div>Loading</div>) }

    //Storign user data into a User variable
    let user = data?.user || ''

    //Storing whether or not the User is authorized to coinbase with a Boolean
    let walletAuthorized = user.walletAuthorized;

    const syncPhantom = async () => {

        //Check to see if user has Phantom Wallet Installed
        if ("phantom" in window) {
            console.log("In the window");
            const provider = window.phantom?.solana;
            if (provider.isPhantom) {
                console.log("The provider is found")
                //Connect to Phantom Wallet, and set Public Key
                try {

                    const network = "https://api.mainnet-beta.solana.com";
                    const connection = new web3.Connection(network);

                    const resp = await window.phantom.solana.connect();
                    const keyString = resp.publicKey.toString();


                    await storePubKey({
                        variables: {
                            id: userId,
                            pubKey: keyString
                        }
                    })

                    const phantomAccount = await connection.getAccountInfo(resp.publicKey);

                    await storeWalletValue({
                        variables: {
                            id: userId,
                            walletAmount: parseFloat((phantomAccount.lamports / 1000000000))
                        }
                    })

                    refetch();

                } catch (e) {

                    console.log(200);

                }
            } else {
                console.log("Test fail")
            }
        } else {

            setDirect(true);

        }

    }

    const directToPhantom = () => {
        window.open("https://phantom.app/", "_blank");
    }

    const deactivateWallet = async (e) => {
        e.preventDefault();

        window.solana.disconnect();

        try {

            await revokeTokens({
                variables: {
                    id: user._id
                }
            })

            refetch();

        } catch (e) {
            console.log(e);
        }

    }

    //Handle Form Input Changes
    const handleEmailChange = (event) => {
        const { value, name } = event.target;
        setEmailFormState({ ...emailFormState, [name]: value })
    }

    const handlePasswordChange = (event) => {
        const { value, name } = event.target;
        setPasswordFormState({ ...passwordFormState, [name]: value })
    }

    const handleInfoChange = (event) => {
        const { value, name } = event.target;
        setInfoFormState({ ...infoFormState, [name]: value })
    }

    //Update Functions & Use Mutations
    //Update Email
    const updateEmail = async (event) => {
        event.preventDefault();

        if (emailFormState.email === emailFormState.emailCheck) {

            try {

                await updateUser({
                    variables: {
                        id: user._id,
                        email: emailFormState.email
                    }
                })

                window.location.reload()

            } catch (e) {
                console.log(e);
            }

        } else {
            alert('Emails do not match!')
        }

    }

    //Update Password
    const updatePassword = async (event) => {
        event.preventDefault();

        const checkPassword = bcrypt.compareSync(passwordFormState.existingPassword, user.password);

        if (!checkPassword) {
            alert("Existing Password Did Not Match!");
            return;
        }

        if (passwordFormState.password === passwordFormState.passwordCheck) {

            try {

                await updateUser({
                    variables: {
                        id: user._id,
                        password: passwordFormState.password
                    }
                })

                window.location.reload()

            } catch (e) {
                console.log(e);
            }
        } else {
            alert("Passwords Did Not Match!")
        }
    }

    //Update Info
    const updateInfo = async (event) => {
        event.preventDefault();

        try {

            await updateUser({
                variables: {
                    id: user._id,
                    firstName: infoFormState.firstName,
                    lastName: infoFormState.lastName
                }
            })

            window.location.reload();

        } catch (e) {
            console.log(e)
        }
    }

    //Function To Show Proper Forms when Selected
    const showForms = (input) => {

        if (input === 'back') {
            setMainNav(true); setOpenEmail(false); setOpenPassword(false);
            setOpenInfo(false); setOpenProfilePic(false); setOpenCoinbase(false);
            setOpenEmail(false);
        }

        if (input === 'email') {
            setMainNav(false); setOpenEmail(false); setOpenPassword(false);
            setOpenInfo(false); setOpenProfilePic(false); setOpenCoinbase(false);
            setOpenEmail(true);
        }

        if (input === 'password') {
            setMainNav(false); setOpenEmail(false); setOpenPassword(true);
            setOpenInfo(false); setOpenProfilePic(false); setOpenCoinbase(false);
            setOpenEmail(false);
        }

        if (input === 'info') {
            setMainNav(false); setOpenEmail(false); setOpenPassword(false);
            setOpenInfo(true); setOpenProfilePic(false); setOpenCoinbase(false);
            setOpenEmail(false);

            setInfoFormState({
                firstName: user.firstName,
                lastName: user.lastName
            })
        }

        if (input === 'pic') {
            setMainNav(false); setOpenEmail(false); setOpenPassword(false);
            setOpenInfo(false); setOpenProfilePic(true); setOpenCoinbase(false);
            setOpenEmail(false);
        }

        if (input === 'wallet') {
            setMainNav(false); setOpenEmail(false); setOpenPassword(false);
            setOpenInfo(false); setOpenProfilePic(false); setOpenCoinbase(true);
            setOpenEmail(false);
        }
    }

    //Log user out
    const logout = () => {
        Auth.logout();
    }

    return (

        <div className="viewPort center backdrop">
            <div className="jumbotron jStyle cardBorder">
                <div className="container-fluid">
                    <div className="row">
                        <div className="d-flex flex-column align-items-center">
                            <div className="d-flex align-items-start m-0 titleBorder">
                                <p className="title1 m-0"><b>Rush</b></p>
                                <p className="title2 m-0"> V2</p>
                            </div>
                            <div className="m-1">
                                <p className="tag-line m-0">Sports Betting Made Simple</p>
                            </div>
                            <div className='d-flex align-self-start searchMargin'>
                                <p className="m-1 goldT"></p>
                                <p className="m-1 grayT"></p>
                                <p className="m-1 grayT"></p>
                            </div>
                            <div className="d-flex justify-content-start w-100">

                            </div>

                            {/* If Main Nav is true than display menu to user */}
                            {mainNav &&
                                <div className="mainSettingsCard">
                                    <div className="settingsTextStyling">
                                        <Link to="#" className="navStyleNU" onClick={() => showForms('email')}>Update Email</Link>
                                    </div>
                                    <div className="settingsTextStyling">
                                        <Link to="#" className="navStyleNU" onClick={() => { showForms('password') }}>Update Password</Link>
                                    </div>
                                    <div className="settingsTextStyling">
                                        <Link to="#" className="navStyleNU" onClick={() => { showForms('info') }}>Update Info</Link>
                                    </div>
                                    <div className="settingsTextStyling">
                                        <Link to="#" className="navStyleNU" onClick={() => { showForms('pic') }}>Update Profile Pic</Link>
                                    </div>
                                    <div className="settingsTextStyling">
                                        <Link to="#" className="navStyleNU" onClick={() => { showForms('wallet') }}>Sync Phantom Wallet</Link>
                                    </div>
                                </div>
                            }

                            {/* Update Email Form */}
                            {openEmail &&
                                <>
                                    <div className="d-flex justify-content-start w-100">
                                        <Link to="#" className="navStyleNU" onClick={() => showForms('back')}>{`<--Back`}</Link>
                                    </div>
                                    <Form className="settingsCard">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <div className="d-flex flex-column">
                                                <Form.Label className="formTitleText m-0"><b>Enter New Email Address</b></Form.Label>
                                                <Form.Label>
                                                    <Form.Text className="text-muted">
                                                        current email: {user.email}
                                                    </Form.Text>
                                                </Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email..."
                                                    name="email"
                                                    value={emailFormState.email}
                                                    onChange={handleEmailChange}
                                                />
                                            </div>
                                            <br />
                                            <Form.Label className="formTitleText m-0"><b>Confirm New Email Address</b></Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Email..."
                                                name="emailCheck"
                                                value={emailFormState.emailCheck}
                                                onChange={handleEmailChange}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <button className="styleBtns" onClick={updateEmail}>Update</button>
                                        </Form.Group>
                                    </Form>
                                </>
                            }
                            {/* Update Password Form */}
                            {openPassword &&
                                <>
                                    <div className="d-flex justify-content-start w-100">
                                        <Link to="#" className="navStyleNU" onClick={() => showForms('back')}>{`<--Back`}</Link>
                                    </div>
                                    <Form className="settingsCard">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <div className="d-flex flex-column">
                                                <Form.Label className="formTitleText m-0"><b>Enter Existing Password</b></Form.Label>
                                            </div>
                                            <Form.Control
                                                type="password"
                                                placeholder="password..."
                                                name="existingPassword"
                                                value={passwordFormState.existingPassword}
                                                onChange={handlePasswordChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <div className="d-flex flex-column">
                                                <Form.Label className="formTitleText m-0"><b>Enter New Password</b></Form.Label>
                                            </div>
                                            <Form.Control
                                                type="password"
                                                placeholder="password..."
                                                name="password"
                                                value={passwordFormState.password}
                                                onChange={handlePasswordChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <div className="d-flex flex-column">
                                                <Form.Label className="formTitleText m-0"><b>Confirm New Password</b></Form.Label>
                                            </div>
                                            <Form.Control
                                                type="password"
                                                placeholder="password..."
                                                name="passwordCheck"
                                                value={passwordFormState.passwordCheck}
                                                onChange={handlePasswordChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <button className="styleBtns" onClick={updatePassword}>Update</button>
                                        </Form.Group>
                                    </Form>
                                </>
                            }
                            {/* Update Info Form */}
                            {openInfo &&
                                <>
                                    <div className="d-flex justify-content-start w-100">
                                        <Link to="#" className="navStyleNU" onClick={() => showForms('back')}>{`<--Back`}</Link>
                                    </div>
                                    <Form className="settingsCard">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <div className="d-flex flex-column">
                                                <Form.Label className="formTitleText m-0"><b>Enter First Name</b></Form.Label>
                                            </div>
                                            <Form.Control
                                                type="text"
                                                placeholder={infoFormState.firstName}
                                                name="firstName"
                                                value={infoFormState.firstName}
                                                onChange={handleInfoChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <div className="d-flex flex-column">
                                                <Form.Label className="formTitleText m-0"><b>Enter Last Name</b></Form.Label>
                                            </div>
                                            <Form.Control
                                                type="text"
                                                placeholder={infoFormState.lastName}
                                                name="lastName"
                                                value={infoFormState.lastName}
                                                onChange={handleInfoChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <button className="styleBtns" onClick={updateInfo}>Update</button>
                                        </Form.Group>
                                    </Form>
                                </>
                            }
                            {/* Update Profile Picture Form */}
                            {openProfilePic &&
                                <>
                                    <Link to="#" className="navStyleNU" onClick={() => showForms('back')}>{`<--Back`}</Link>
                                    <Form><p className="textWhite">Feature Coming Soon!</p></Form>
                                </>
                            }
                            {/* Update Coinbase Wallet Form */}
                            {openCoinbase &&
                                <>
                                    <div className="d-flex justify-content-start w-100">
                                        <Link to="#" className="navStyleNU" onClick={() => showForms('back')}>{`<--Back`}</Link>
                                    </div>
                                    <Form className="settingsCard">
                                        {walletAuthorized ? (
                                            <div>
                                                <div className="d-flex flex-column align-items-center">
                                                    <h1 className="linkCoinbase centerText">Phantom Wallet Authorized! ✅</h1>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center flex-column">
                                                    {/* <p className="textWhite m-1">Public Key: {user.cryptoPubKey}</p> */}
                                                    <p className="textWhite m-1">Account Value: {user.accountValue} Sol</p>
                                                    {/* <p className="textWhite m-1">Add Coins via <Link className="navStyleNU" to="#" onClick={addCoins}>coinbase.com</Link></p> */}
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <Link className="deactivateLink" to="#" onClick={deactivateWallet}>Deactivate Wallet</Link>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {direct ? (
                                                    <Link className="linkCoinbase" to="#" onClick={directToPhantom}>Phantom Wallet Not Detected. Click to Download.</Link>
                                                ) : (
                                                    <Link className="linkCoinbase" to="#" onClick={syncPhantom}>Authorize Phantom Wallet</Link>
                                                )}
                                            </>

                                        )}

                                    </Form>
                                </>
                            }
                            <div className="d-flex m-2">
                                <Link to={`/dashboard/${Auth.getContractor().data._id}`} className="navStyle">{`Dashboard`}</Link>
                                <Link to={`#`} className="navStyle" onClick={() => logout()}>{`Logout`}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings;