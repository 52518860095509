import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { REVOKE_TOKENS, STORE_WALLET_VALUE } from '../../utils/mutations';

const web3 = require('@solana/web3.js');

const CryptoInteraction = ({ user, refetch }) => {

    const [storeWalletValue] = useMutation(STORE_WALLET_VALUE);
    const [revokeTokens] = useMutation(REVOKE_TOKENS);

    const deactivateWallet = async (e) => {
        //e.preventDefault();

        //window.solana.disconnect();

        try {

            await revokeTokens({
                variables: {
                    id: user._id
                }
            })

            refetch();

        } catch (e) {
            console.log(e);
        }

    }

    useEffect(async () => {

        const isAuth = user?.walletAuthorized || false;

        if (isAuth) {

            //Network specifc to live Main Network of Solara
            const network = "https://api.mainnet-beta.solana.com";

            //Initializing connection
            const connection = new web3.Connection(network);

            //Convert public key string into readable publick Key
            const toPublicKey = new web3.PublicKey(user.cryptoPubKey);

            try {
                // Grab Account Info
                const phantomAccount = await connection.getAccountInfo(toPublicKey);

                // If the account information was successfully retrieved, it means the public key is authorized.
                console.log("Public Key is authorized:", phantomAccount);

                await storeWalletValue({
                    variables: {
                        id: user._id,
                        walletAmount: parseFloat((phantomAccount.lamports / 1000000000))
                    }
                });

                refetch();
            } catch (error) {
                deactivateWallet();
                refetch();
                // Handle errors
                console.error("Error fetching account info:", error);
            }

        } else {
            console.log("User Not Connected");
        }

    }, []);


    return (
        <></>
    )
}

export default CryptoInteraction;