import React, { useState, useEffect } from 'react';

import MobileSideNav from '../MobileSideNav';

import search from '../../utils/API'
import List from '../List';

function SearchPage ({ inputBetween, setInputBetween, cryptoPrice }){

    //Forcing HTML Background to be black
    document.body.style.backgroundColor = "black";

    //FormStates
    //results will store the Search Results from querying Live Odds API
    const [results, setResults] = useState([]);

    //Variable to render "loading data..." until axios request is complete
    const [awaitAxios, setAwaitAxios] = useState('t');

    //showSideNav will be used to display the sidenav on and off
    const [ showSideNav, setShowSideNav ] = useState('');
    //navBtn will display depending on screen size
    const [ navBtn, setNavBtn] = useState('t')
    
    //Function to query Live Odds API from search utils
    const searchOdds = async (query) => {

        //Set the response status for Axios to True (Waiting for Axios Request)
        setAwaitAxios('t');

        //Search calls the Live Odds API in utils
        const response = await search(query);

        //Set the data array in the response object to results
        setResults(response?.data);

        //If response status is 200 Set awaitAxios to false, since we returned data
        if(response.status === 200){
            setAwaitAxios('');
        }
    
    }

    // Moderates the SearchOdds function to access API when searched
    useEffect(() => {
        
        //Set Results to an empty array
        setResults([])
        
        //Set InputBetween Homepage & Searchpage from localstorage saved item
        setInputBetween(localStorage.getItem('search'))

        //If inputBetween is false return and do not search odds. If search there will be errors on empty arrays & rendering from list page
        if(!inputBetween){
            return;
        }

        //SearchOdds calls the Live Odds API above
        searchOdds(inputBetween);

    }, [inputBetween]);

    //Show Side Nav and close Nav Bar Button
    const openNav = () => {
        setShowSideNav('t');
        setNavBtn('');
    }

    //Show Nav Button and Close Side Nav
    const closeNav = () => {
        setShowSideNav('');
        setNavBtn('t');
    }

    return (
        <div className="main d-flex">
            {/* On Mobile, this button allows user to open up the Side Nav */}
            <div className="openNav">
               {navBtn ? (<button className="coolBeans" onClick={openNav}>➔</button>) : (<></>)} 
            </div>
            {showSideNav ? (
                <div className="mobileSideNav">
                    <div className="sideNavBorders">
                        <MobileSideNav closeNav={closeNav} inputBetween={inputBetween} setInputBetween={setInputBetween} />
                    </div>
                </div>
            ) : (<></>)}
            {/* Render Results in List Component */}
            <List results={results} awaitAxios={awaitAxios} cryptoPrice={cryptoPrice}/>
        </div>
    )
}

export default SearchPage;