import React, { useState } from 'react';
import Odds from '../Odds';

import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import moment from 'moment';

import Modal from 'react-bootstrap/Modal';

import Ticket from '../Ticket';
import Confirmation from '../Confirmation'

import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ONE_USER } from '../../utils/queries';
import { allSearch, generalSearchCheck } from '../../utils/SportsArrays/AllSports';
import sportsConverter from '../../utils/sportsConverter';

function List({ results, awaitAxios, cryptoPrice }) {

    // useStates to handle data being passed into Modal
    const [team1, setTeam1] = useState('');
    const [team2, setTeam2] = useState('');
    const [dateTime, setDateTime] = useState('');
    const [date, setDate] = useState('');
    const [league, setLeague] = useState('');
    const [homeTeam, setHomeTeam] = useState('');
    const [awayTeam, setAwayTeam] = useState('');
    const [time, setTime] = useState('');
    const [spread1, setSpread1] = useState('');
    const [spreadPrice1, setSpreadPrice1] = useState('');
    const [spread2, setSpread2] = useState('');
    const [spreadPrice2, setSpreadPrice2] = useState('');
    const [ml1, setml1] = useState('');
    const [ml2, setml2] = useState('');
    const [drawPrice, setDrawPrice] = useState('');
    const [overUnder, setOverUnder] = useState('');
    const [over, setOver] = useState('');
    const [under, setUnder] = useState('');

    const [ticket, setTicket] = useState([]);

    const [between, setBetween] = useState([])

    // Show Modal to place Bets
    const [show, setShow] = useState('');

    // Show Modal to confirm Bets
    const [showTicket, setShowTicket] = useState('');
    const [showCancel, setShowCancel] = useState('');

    const { userId } = useParams();
    const { loading, data, refetch } = useQuery(GET_ONE_USER, {
        variables: { id: userId }
    });

    const selectBookmaker = 'betus';

    if (loading) {
        return (
            <div>Loading</div>
        )
    }

    //Storing User Data into a user variable
    const user = data?.user;

    //Storing Match Between Object that will either be against rushV2 or another Player
    let defaultBetween = [{
        player1: [
            {
                email: user?.email || '',
                for: true
            }
        ],
        player2: [
            {
                email: 'rushv2',
                for: false
            }
        ]
    }]





    // Handling Modal closing functionality
    const handleClose = () => setShow('');
    const handleCloseTicket = () => setShowTicket('');
    const handleCancelTicket = () => { setShowTicket(''); setTicket([]); setBetween(defaultBetween) }

    const handleShowTicket = () => {
        setShow('');
        setShowTicket('t');
    }

    // Sets the Elements from the Selected Button for the useState variables that will be used in the Modal
    const handleShow = (event) => {

        setBetween(defaultBetween);
        setTeam1(event.currentTarget.children[1].children[0].children[0].children[1].textContent);
        setTeam2(event.currentTarget.children[1].children[0].children[0].children[3].textContent);
        setDateTime(event.currentTarget.children[0].children[0].textContent)
        setDate(event.currentTarget.children[0].children[1].children[0].textContent);
        setTime(event.currentTarget.children[0].children[2].children[0].textContent);
        setLeague(event.currentTarget.children[0].children[3].textContent);
        setAwayTeam(event.currentTarget.children[0].children[4].textContent);
        setHomeTeam(event.currentTarget.children[0].children[5].textContent);
        setSpread1(event.currentTarget.children[1].children[0].children[1].children[0].children[0].textContent);
        setSpreadPrice1(event.currentTarget.children[1].children[0].children[1].children[0].children[2].textContent);
        setSpread2(event.currentTarget.children[1].children[0].children[1].children[1].children[0].textContent);
        setSpreadPrice2(event.currentTarget.children[1].children[0].children[1].children[1].children[2].textContent);
        setml1(event.currentTarget.children[1].children[0].children[2].children[0].textContent);
        setml2(event.currentTarget.children[1].children[0].children[2].children[1].textContent);
        setOverUnder(event.currentTarget.children[1].children[0].children[3].children[1].children[1].textContent);
        setOver(event.currentTarget.children[1].children[0].children[3].children[0].children[3].textContent);
        setUnder(event.currentTarget.children[1].children[0].children[3].children[1].children[3].textContent);
        setDrawPrice(event.currentTarget.children[1].children[0].children[2].children[2]?.textContent || '')

        setShow('t')
    };

    // Saving the Bet once the saveBet button is clicked. Logic will add an object depending on which Button is clicked
    const saveBet = (name) => {

        //Set a bet object. Data will be added inside if statements below.
        let bet = {};

        if (name === 1) {

            bet = {
                type: 'Spread',
                date: dateTime,
                league: league,
                game: `${homeTeam} vs ${awayTeam}`,
                team: team1,
                spread: parseFloat(spread1),
                odds: parseInt(spreadPrice1)
            }
        }

        if (name === 2) {

            bet = {
                type: 'Spread',
                date: dateTime,
                league: league,
                game: `${homeTeam} vs ${awayTeam}`,
                team: team2,
                spread: parseFloat(spread2),
                odds: parseInt(spreadPrice2)
            }
        }

        if (name === 3) {

            bet = {
                type: 'Money Line',
                date: dateTime,
                league: league,
                game: `${homeTeam} vs ${awayTeam}`,
                team: team1,
                spread: 0,
                odds: parseInt(ml1)
            }
        }

        if (name === 4) {

            bet = {
                type: 'Money Line',
                date: dateTime,
                league: league,
                game: `${homeTeam} vs ${awayTeam}`,
                team: team2,
                spread: 0,
                odds: parseInt(ml2)
            }
        }

        if (name === 5) {

            bet = {
                type: 'Over',
                date: dateTime,
                league: league,
                game: `${homeTeam} vs ${awayTeam}`,
                team: `${team1}`,
                spread: parseFloat(overUnder),
                odds: parseInt(over)
            }
        }

        if (name === 6) {

            bet = {
                type: 'Under',
                date: dateTime,
                league: league,
                game: `${homeTeam} vs ${awayTeam}`,
                team: `${team1}`,
                spread: parseFloat(overUnder),
                odds: parseInt(under)
            }
        }

        if (name === 7) {
            bet = {
                type: 'Money Line (Draw)',
                date: dateTime,
                league: league,
                game: `${homeTeam} vs ${awayTeam}`,
                team: `${homeTeam}`,
                spread: 0,
                odds: parseInt(drawPrice)
            }
        }

        for(let i = 0; i < ticket.length; i++){
            if(bet.type === ticket[i].type && bet.date === ticket[i].date && bet.league === ticket[i].league && bet.team === ticket[i].team){

                alert("Bet has already been added to ticket");
                return;
            }
        }

        setTicket([...ticket, bet]);
        console.log(ticket, "test log");

    }

    //Render Only If the Results object contains the correct Bookmaker. 
    //Currently rendering "betus" odds, but will need to create a state variable to update as needed.
    const renderOnlyIfMeetsBookmaker = (result) => {

        let timeNow = moment(Date.now())
        let gameTime = moment(result.commence_time)
        let searchInput = localStorage.getItem('search')
        let generalSearch;

        //Stops a bet from rendering If game is already in progress.
        //May need to set up an additional outlet for live games????
        if (timeNow > gameTime) {
            return (
                <div></div>
            )
        }

        //Set whether the General Search is true or false
        if (allSearch(searchInput)) {
            //True means it is a General Search (Football, NFL...)
            //False means it is a specific Search (Green Bay Packers)
            if (generalSearchCheck(searchInput)) {
                generalSearch = true;
            } else {
                generalSearch = false
            }

        };

        //Stores League Type
        let storeLeague = result.sport_title;

        //Converting team names into a different string that will help with Game Results API
        let homeTeamConverted = sportsConverter(result.home_team);
        let awayTeamConverted = sportsConverter(result.away_team);

        //Converts EPL league type into English Premier League - This is for storing the Data object
        //It will match the URL link for when we need to grab game results object from separate API
        if(storeLeague === "EPL"){
            storeLeague = "English Premier League"
        }

        //If the SearchInput is general render...
        if (generalSearch) {
            //For each bookmaker in the results object...
            for (let i = 0; i < result.bookmakers.length; i++) {
                //If the Bookmaker matches betus render...
                if (result.bookmakers[i].key === selectBookmaker) {

                    //If Bookmaker is not returning the full set of odds, do not render the game
                    if(result.bookmakers[i].markets.length < 3){
                        return(
                            <></>
                        )
                    }

                    return (
                        <div className="propCard flex-fill m-2 titleBorder" key={result.id}>
                            <button className="noBtnStyle maxGameCardWidth" onClick={handleShow}>
                                <div className="row m-0">
                                    <div className="displayNONE">{result.commence_time}</div>
                                    <div className="col-6"><Moment className="propTextDate" format="MMM DD">{result.commence_time}</Moment></div>
                                    <div className="d-flex col-6 justify-content-end"><Moment className="propTextTime" format="h:mm a">{result.commence_time}</Moment></div>
                                    <div className="displayNONE">{storeLeague}</div>
                                    <div className="displayNONE">{awayTeamConverted}</div>
                                    <div className="displayNONE">{homeTeamConverted}</div>
                                </div>
                                <Odds result={result} />
                                <div className="row m-0">
                                    <p className="propText">{`${homeTeamConverted} @ Home`}</p>
                                </div>
                            </button>
                        </div>
                    )
                }
            }

        }

        //If searchInput has an under score - than remove that underscore to render a specific game (this is mainly for college sports where _NCAAB is at the end of a string)
        if(searchInput.includes("_")){
            let substring = searchInput.split('_');
            searchInput = substring[0];
        }

        //If search is specific render...
        if (!generalSearch) {
            //if result object is either in Away Team or Home Team render...
            if (result.away_team === searchInput || result.home_team === searchInput) {
                //Loop through all the bookmakers that are provided in the result object...
                for (let i = 0; i < result.bookmakers.length; i++) {
                    //If the Bookmaker equals betus render...
                    if (result.bookmakers[i].key === selectBookmaker) {

                        //If Bookmaker is not returning the full set of odds, do not render the game
                        if(result.bookmakers[i].markets.length < 3){
                            return(
                                <></>
                            )
                        }

                        //Render HTML Element
                        return (
                            <div className="propCard flex-fill m-2 titleBorder" key={result.id}>
                                <button className="noBtnStyle maxGameCardWidth" onClick={handleShow}>
                                    <div className="row m-0">
                                        <div className="displayNONE">{result.commence_time}</div>
                                        <div className="col-6"><Moment className="propTextDate" format="MMM DD">{result.commence_time}</Moment></div>
                                        <div className="d-flex col-6 justify-content-end"><Moment className="propTextTime" format="h:mm a">{result.commence_time}</Moment></div>
                                        <div className="displayNONE">{storeLeague}</div>
                                        <div className="displayNONE">{awayTeamConverted}</div>
                                        <div className="displayNONE">{homeTeamConverted}</div>
                                    </div>
                                    <Odds result={result} />
                                    <div className="row m-0">
                                        <p className="propText">{`${homeTeamConverted} @ Home`}</p>
                                    </div>
                                </button>
                            </div>
                        )
                    }
                }
            }
        }
    }

    return (
        <>
            <div className="heightmax">
                <div className="topSearchPage">
                    <Link to="#" className="seeTicket" onClick={handleShowTicket}>Current Ticket</Link>
                </div>
                <div className="d-flex flex-wrap">
                    <>
                        {awaitAxios ? (
                            <div className="d-flex justify-content-center w-100">
                                <h3 className="textWhite m-5">Loading Results...</h3>
                            </div>
                        ) : (
                            <>
                                {results.map((result) => (
                                    renderOnlyIfMeetsBookmaker(result)
                                ))}
                            </>
                        )}
                    </>
                </div>
                {awaitAxios ? (<></>) : (<div className="textWhite d-flex align-items-end font20">All Available Games Showing...</div>)}
                {/* Modal to Place Bets */}
                <Modal show={show} onHide={handleClose} className="modalCard">
                    <div className="d-flex justify-content-between">
                        <p className="textWhite modalTime">{date}</p>
                        <p className="textWhite modalTime">{time}</p>
                    </div>
                    <Modal.Header className="p-1 d-flex justify-content-center">
                        <Modal.Title className="textWhite modalTitle">{team1} vs. {team2}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row nopadding separator topBorder">
                                <div className="col-2 centerIt rightBorder textSpacer">Spread</div>
                                <div className="col-5 centerIt">
                                    <div className="textSpacer">{team1}</div>
                                    <div className="textSpacer">{team2}</div>
                                </div>
                                <div className="col-3 centerIt">
                                    <div className="textSpacer">{spread1} | {spreadPrice1}</div>
                                    <div className="textSpacer">{spread2} | {spreadPrice2}</div>
                                </div>
                                <div className="col-2 centerIt">
                                    <Link className="addLinkBtn" to="#" onClick={() => saveBet(1)}>+Add</Link>
                                    <Link className="addLinkBtn" to="#" onClick={() => saveBet(2)}>+Add</Link>
                                </div>
                            </div>
                            <div className="row nopadding separator">
                                <div className="col-2 centerIt rightBorder textSpacer">Money Line</div>
                                <div className="col-5 centerIt">
                                    <div className="textSpacer">{team1}</div>
                                    {drawPrice ? (<div className="textSpacer">Draw: </div>) : (<></>)}
                                    <div className="textSpacer">{team2}</div>
                                </div>
                                <div className="col-3 centerIt">
                                    <div className="textSpacer">{ml1}</div>
                                    {drawPrice ? (<div className="textSpacer">{drawPrice}</div>) : (<></>)}
                                    <div className="textSpacer">{ml2}</div>
                                </div>
                                <div className="col-2 centerIt">
                                    <Link className="addLinkBtn" to="#" onClick={() => saveBet(3)}>+Add</Link>
                                    {drawPrice ? (<Link className="addLinkBtn" to="#" onClick={() => saveBet(7)}>+Add</Link>) : (<></>)}
                                    <Link className="addLinkBtn" to="#" onClick={() => saveBet(4)}>+Add</Link>
                                </div>
                            </div>
                            <div className="row nopadding separator">
                                <div className="col-2 centerIt rightBorder textSpacer">Over / Under</div>
                                <div className="col-5 centerIt">
                                    <div className="textSpacer">{team1}</div>
                                    <div className="textSpacer">{team2}</div>
                                </div>
                                <div className="col-3 centerIt">
                                    <div className="textSpacer">O{overUnder} | {over}</div>
                                    <div className="textSpacer">U{overUnder} | {under}</div>
                                </div>
                                <div className="col-2 centerIt">
                                    <Link className="addLinkBtn" to="#" onClick={() => saveBet(5)}>+Add</Link>
                                    <Link className="addLinkBtn" to="#" onClick={() => saveBet(6)}>+Add</Link>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Body>
                        <div className="textSpacer">Current Ticket:</div>
                        {ticket ? (<Ticket ticket={ticket} setTicket={setTicket} handleShowTicket={handleShowTicket} />) : (<div className="propText">No Bets</div>)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Link className="addLinkBtn" to="#" onClick={handleClose}>
                            Close
                        </Link>
                    </Modal.Footer>
                </Modal>

                {/* Modal to Confirm Bets */}
                <Modal show={showTicket} onHide={handleCloseTicket} className="modalCard">
                    <Modal.Header className="p-1 d-flex justify-content-center">
                        <Modal.Title className="textWhite modalTitle"> Current Ticket:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ticket ? (<Confirmation user={user} ticket={ticket} setTicket={setTicket}
                            setShowCancel={setShowCancel} between={between} setBetween={setBetween} handleCancelTicket={handleCancelTicket}
                            defaultBetween={defaultBetween} cryptoPrice={cryptoPrice} refetch={refetch} />
                        ) : (
                            <div>No Bets</div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {showCancel ? (
                            <Link className="addLinkBtn" to="#" onClick={handleCancelTicket}>
                                Cancel
                            </Link>
                        ) : (
                            <Link className="addLinkBtn" to="#" onClick={handleCloseTicket}>
                                Close
                            </Link>
                        )}
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default List;