import React from 'react'

import { allSports } from '../../utils/SportsArrays/AllSports';

const TeamSearching = ({ inputBetween, setInputBetween }) => {

    //Sets searchBar text to the Input that the button is clicked from the filtered selection
    const updateInputBetween = (filteredName) => {

        setInputBetween(filteredName);

    }

    //Filters out many results to just 6
    const handleRender = (filteredName, index) => {

        for (let i = 0; i < allSports.length; i++ ) {
            if (inputBetween === allSports[i]) {   
                return (<></>)
            }
        }

        return (
            <>
                {index <= 5 ? (
                    <div className="searchBoxListElements">
                        <button onClick={() => updateInputBetween(filteredName)} className="noBtnStyle listBtnElements">
                            {filteredName}
                        </button>
                    </div>
                ) : (
                    <></>
                )}
                </>
            
        )


    }

    //Returns a filtered selection via filter and map options
    return (
        <>
            <div className="searchBoxList">
                {allSports.filter(name => name.includes(inputBetween)).map((filteredName, index) => (
                    <div key={index}>
                        {handleRender(filteredName, index)}
                    </div>
                ))}
            </div>
        </>
    )
}

export default TeamSearching