import React, { useState } from 'react';
import { ADD_BET } from '../../utils/mutations';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ONE_USER } from '../../utils/queries';
import Auth from '../../utils/auth';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Challenge from '../Challenge';
import phantomTransaction from '../../utils/walletTransactions';

const bcrypt = require('bcryptjs');

function Confirmation({ user, ticket, setTicket, setShowCancel, between, setBetween, handleCancelTicket, defaultBetween, cryptoPrice, refetch }) {

    // Variable to Add up the odds depending on if it's a parlay Bet
    let accu = 1;
    let decimal;

    const [formState, setFormState] = useState({
        betAmount: '',
        password: ''
    });
    const [winAmount, setWinAmount] = useState(0);
    const [buttonChange, setButtonChange] = useState('t');
    const [betRush, setBetRush] = useState('');
    const [betFriend, setBetFriend] = useState('');
    const [options, setOptions] = useState('t');
    const [cryptoBetAmount, setCryptoBetAmount] = useState(0)
    const [cryptoWinAmount, setCryptoWinAmount] = useState(0);

    const [addBet] = useMutation(ADD_BET);

    const password = user?.password || '';
    const walletAuthorized = user?.walletAuthorized || false;

    // Delete a Bet Object from the Ticket Array when the delete button is clicked
    const removeBet = (i) => setTicket(ticket.filter((bet, index) => index !== i));

    const handleChange = (event) => {

        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value
        })

        if (name == "betAmount") {
            setWinAmount('');
            setButtonChange('t');
        }


    }

    const toWin = (e) => {
        e.preventDefault();

        ticket.forEach(bet => {
            let number = parseInt(bet.odds);

            if (Math.sign(number) == 1) {
                decimal = (number / 100) + 1
            }

            if (Math.sign(number) == -1) {
                decimal = 1 - (100 / number)
            }

            accu = accu * decimal;
        });

        //Store dollar Amount
        let dollarAmount = Math.floor((formState.betAmount * accu)) - formState.betAmount

        //Set toWin Amount for webpage
        setWinAmount(dollarAmount);

        //Converting $$$ Amount into Litecoin Crypto Price
        setCryptoBetAmount((formState.betAmount / cryptoPrice).toFixed(3));

        //Convering Win Amount into Crypto Litecoin Price
        setCryptoWinAmount((dollarAmount / cryptoPrice).toFixed(3));

        accu = 1;
        setButtonChange('');

    }

    const placeBet = async (e) => {
        e.preventDefault();

        const checkPassword = bcrypt.compareSync(formState.password, password);

        if (!checkPassword) {
            alert("Failed Password");
            return;
        }
        
        if(cryptoBetAmount > user.accountValue){
            alert(`Easy buddy. You don't have that kind of coing. ${cryptoBetAmount} exceeds ${user.accountValue}.`);
            return;
        }

        if (parseFloat(formState.betAmount) >= 6) {
            alert(`$5 Maximum bets.`);
            return;
        }

        if (ticket.length >= 1) {
            try {

                await phantomTransaction(user.cryptoPubKey, cryptoBetAmount);

                await addBet({
                    variables: {
                        id: user._id,
                        riskAmount: parseFloat(cryptoBetAmount),
                        toWinAmount: parseFloat(cryptoWinAmount),
                        between: between,
                        ticket: ticket
                    },
                })

                setButtonChange('t');
                handleCancelTicket();
                refetch();

            } catch (error) {
                console.log(error)
            }
        } else {
            alert("No Bets selected");
        }

    }

    //On Click functions to show the bet against Rush Form or the Bet against Friend Form
    const showBetRush = () => { setOptions(''); setBetRush('t'); }
    const showBetFriend = () => { setOptions(''); setBetFriend('t'); setShowCancel('t') }
    const closeBetRushOrFriend = (originalTicket) => {
        setBetRush('');
        setBetFriend('');
        setOptions('t')
        setTicket([]);
        setBetween(defaultBetween);
    };

    return (

        <>
            {ticket.length == 0 ? (<div className="propText">No Wagers Selected Yet, Friend.</div>) : (
                <>
                    {ticket.map((bet, index) => (
                        <div className="container-fluid p-0" key={index}>
                            <div className="d-flex row m-0">
                                <div className="col-1 deleteBtnFlex">
                                    <Link className="deleteLink" to="#" onClick={() => removeBet(index)}><b>X</b></Link>
                                </div>
                                <div className="col-2 currentTicket">{bet.type}</div>
                                <div className="col-5 currentTicket">{bet.team}</div>
                                <div className="col-2 currentTicket">{bet.spread}</div>
                                <div className="col-2 currentTicket">{bet.odds}</div>
                            </div>
                        </div>
                    ))}

                </>
            )}
            <br />
            {options ? (
                <>
                    {/* If User is logged In Display -- */}
                    {Auth.loggedIn() ? (
                        <>
                            {/* If User has synced their coinbase account display Bet Options */}
                            {walletAuthorized ? (
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-5">
                                            <button className="noBtnStyle d-flex justify-content-end" onClick={showBetRush}>
                                                <div className="optionBoxRush">
                                                    <p className="rushOptionText">Place</p>
                                                    <p className="rushOptionText">This</p>
                                                    <p className="rushOptionText">Bet</p>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="col-2 propText d-flex justify-content-center align-items-center">OR</div>
                                        <div className="col-5">
                                            <button className="noBtnStyle d-flex justify-content-start" onClick={showBetFriend}>
                                                <div className="optionBoxFriend">
                                                    <p className="rushOptionText">Challenge</p>
                                                    <p className="rushOptionText">A</p>
                                                    <p className="rushOptionText">Friend</p>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {/*If User is has not synced Wallet direct them to Settings Page*/}
                                    <div className="d-flex justify-content-center">
                                        <Link className="navStyle m-0" to={`/settings/${Auth.getContractor().data._id}`}>Authorize Crypto Wallet</Link>
                                        <p className="propText spaceFromLogin">to place a bet</p>
                                    </div>
                                </>
                                )}
                        </>
                    ) : (
                        <>
                            {/*If User is not logged in direct them to Log In Link*/}
                            <div className="d-flex justify-content-center">
                                <Link className="navStyle m-0" to="/login">Login</Link>
                                <p className="propText spaceFromLogin">to place a bet</p>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <></>
            )}
            {betRush ? (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6">
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="propText">Enter Bet Amount:</Form.Label>
                                    <Form.Control type="number"
                                        placeholder="Bet Amount..."
                                        name="betAmount"
                                        value={formState.betAmount}
                                        onChange={handleChange}
                                        className="inputTextSize" />
                                </Form.Group>
                                {buttonChange ? (
                                    <div className="d-flex justify-content-end">
                                        <Link className="addLinkBtn m-0" to="#" onClick={toWin}>Calculate!</Link>
                                    </div>
                                ) : (
                                    <>
                                        {Auth.loggedIn() ? (
                                            <>
                                                <Form.Label className="propText">Enter Password To Confirm</Form.Label>
                                                <Form.Control type="password"
                                                    placeholder="Password..."
                                                    name="password"
                                                    value={formState.password}
                                                    onChange={handleChange} />
                                                <div className="d-flex justify-content-end">
                                                    <button className="confirmBtn" onClick={placeBet}>Book It!</button>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="d-flex">
                                                <Link className="navStyle m-0" to="/login">Login</Link>
                                                <p className="propText spaceFromLogin">to place a bet</p>
                                            </div>
                                        )
                                        }
                                    </>
                                )
                                }
                            </Form>
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-5">
                                    <div className="propText">
                                        <p className="confirmText1">Betting ($$)</p>
                                        <p className="confirmText2">~${formState.betAmount}</p>
                                        <p className="confirmText1">To Win</p>
                                        <p className="confirmText2">~${winAmount}</p>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div className="propText">
                                        <p className="confirmText1">Betting (SOL)</p>
                                        <p className="confirmText2">{cryptoBetAmount}</p>
                                        <p className="confirmText1">To Win</p>
                                        <p className="confirmText2">{cryptoWinAmount}</p>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <Link className="navStyleNU" to="#" onClick={closeBetRushOrFriend}>X</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {betFriend ? (
                <div>
                    <Challenge
                        user={user}
                        ticket={ticket}
                        setTicket={setTicket}
                        between={between}
                        setBetween={setBetween}
                        defaultBetween={defaultBetween}
                        closeBetRushOrFriend={closeBetRushOrFriend}
                        toWin={toWin}
                        formState={formState}
                        setFormState={setFormState}
                        winAmount={winAmount}
                        setWinAmount={setWinAmount}
                        accu={accu}
                        decimal={decimal}
                        buttonChange={buttonChange}
                        setButtonChange={setButtonChange}
                        cryptoBetAmount={cryptoBetAmount}
                        cryptoWinAmount={cryptoWinAmount}
                        handleCancelTicket={handleCancelTicket}
                        refetch={refetch}
                    />
                </div>) : (
                <></>
            )}
        </>

    )
}

export default Confirmation;