import React, { useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_EMAILS } from '../../utils/queries';
import { SEND_FRIEND_REQUEST } from '../../utils/mutations';
import { ADD_FRIEND } from '../../utils/mutations';
import { DELETE_FRIEND_REQUEST } from '../../utils/mutations';

import { Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import FriendModal from '../FriendModal';

const FriendList = ({ user, closeFriendList, cryptoPrice, refetch }) => {

    //Use states to hanlde any variables that required re-rendering
    const [formState, setFormState] = useState({ input: '' })
    const [addAFriend, setAddAFriend] = useState('');
    const [list, setList] = useState('');
    const [friendIdModal, setFriendIdModal] = useState('');
    const [friendRequestSent, setFriendRequestSent] = useState(0);
    const [decision, setDecision] = useState(0); //Update depending if Friend Request is accepted or denied
    const [specificFriendAdded, setSpecificFriendAdded] = useState('');

    // Show Modal to place Bets
    const [show, setShow] = useState('');

    //Grab the number of friend Requests
    let friendRequests = user.friendRequests.length;

    //USE Query to grab all the users emails for +Add a friend submission
    const { loading, data } = useQuery(GET_USER_EMAILS)

    const [requestFriend] = useMutation(SEND_FRIEND_REQUEST);
    const [addFriend] = useMutation(ADD_FRIEND);
    const [deleteFriendRequest] = useMutation(DELETE_FRIEND_REQUEST);

    //If GET_USER_EMAILS is still querying than rendering loading... div
    if (loading) { return (<div className="textWhite">Loading...</div>) }

    //Store User emails array
    const userEmails = data.userEmails;

    //Handle Input Change for the Friend Request Input Bar
    const handleChange = (event) => {
        event.preventDefault();

        const { value, name } = event.target

        setFormState({
            ...formState,
            [name]: value
        })

    }

    //Submit form works when Enter button is hit
    const onKeyDown = () => {

    }

    //This will toggle the Display Add a Friend Input Bar on & off
    const showAddAFriend = () => {
        if (addAFriend === 't') { setAddAFriend('') }
        if (addAFriend === '') { setAddAFriend('t') };
    }

    //Send a Friend Request Submit Form
    const sendFriendRequest = async (event) => {
        event.preventDefault();
    
        //Set userFound to False. If found userFound will be set to true
        let userFound = false;

        //For Loop to loop through all userEmails and find if Input from User matches anything stored in the database
        userEmails.forEach(userEmail => {
            if (userEmail.email.toLowerCase() === formState.input.toLowerCase()) {
                userFound = true;
            }
        })

        //Reload the page after a Friend request is successfully sent
        const resetPage = () => {
            window.location.reload();
        }

        //If User is Found...
        if (userFound) {
            try {

                //send Friend Request
                await requestFriend({
                    variables: {
                        userId: user._id,
                        email: formState.input
                    }
                })

                setFormState({ input: '' }); //Set send friend request input back to empty string
                setAddAFriend(''); //Close the Input Form
                setFriendRequestSent(1) //Show Div displaying Friend Request has been sent
                setTimeout(resetPage, 2000); //Call function to reload the page after 2 seconds.

            } catch (e) {
                console.log(e)
            }
        } else {
            setFriendRequestSent(2); //If userFound is false display Div showing no user found
        }

    }

    //Toggle Button to switch between Friend List & Friend Requests
    const switchList = () => {
        if (list === 't') { setList('') }
        if (list === '') { setList('t') };
    }

    //onClick function to add a friend from Friend Request List
    const addTheFriend = async (friendId, index) => {

        //Timeout function to reset Index #'s & refetch Data
        const resetIndex = () => {
            refetch()
            setSpecificFriendAdded('')
        };

        try {

            //Add Friend Mutation, to update database
            await addFriend({
                variables: {
                    userId: user._id,
                    friendId: friendId
                }
            })

            setSpecificFriendAdded(index); //Update to specific Index, so Friends List Decision is Rendered
            setDecision(true) //Set true so friendRequest Decision Renders Friend Request Accepted
            setTimeout(() => resetIndex(), 3000); //Timeout function to reset Index #'s & refetch Data

        } catch (e) {
            console.log(e)
        }
    }

    //onClick function to delete a friend request from friend request list
    const deleteTheFriendRequest = async (friendId, index) => {
        
        //Timeout function to reset Index #'s & refetch Data
        const resetIndex = () => {
            refetch()
            setSpecificFriendAdded('')
        };

        try {

            await deleteFriendRequest({
                variables: {
                    userId: user._id,
                    friendId: friendId
                }
            })

            setSpecificFriendAdded(index); //Update to specific Index, so Friends List Decision is Rendered
            setDecision(false); //Set false so friendRequest Decision Renders Friend Request Denied
            setTimeout(() => resetIndex(), 3000); //Timeout function to reset Index #'s & refetch Data

        } catch (e) {
            console.log(e)
        }
    }

    const renderAFriend = (friend, index) => {

        //Storing friend Object Data into variables to be displayed in a friend list card
        let friendWins = friend.wonBets?.length || 0;
        let friendLosses = friend.lostBets?.length || 0;
        let friendTies = friend.tieBets?.length || 0;
        let lastBetTeam = friend.placedBets[0]?.ticket[0].team || ``;
        let lastBetSpread = friend.placedBets[0]?.ticket[0].spread || ``;
        let friendRecord = `${friendWins}/${friendLosses}/${friendTies}`;
        let versus = []; //stores the challenges between friend and current user

        //Loop through friends challenges to determine if any of the challenges are against the current user
        for (let i = 0; i < friend.challengeBets.length; i++) {

            if (friend.challengeBets[i].between[0].player2[0].email == user.email || friend.challengeBets[i].between[0].player1[0].email == user.email) {
                //Store these challenges into an array
                versus.push(friend.challengeBets[i])
            }
        }

        //Adding a + sign to positive numbers. Important when reading spreads
        if (Math.sign(lastBetSpread) == 1) { lastBetSpread = `+${lastBetSpread}` }

        //Render Friend Div
        return (
            <>
                <div className="col-6 d-flex flex-column align-items-start">
                    <p className="m-0 friendListTextEmail">{friend.email}</p>
                    <p className="m-0 friendListTextRecord">Record: {friendRecord}</p>
                </div>
                <div className="col-6 d-flex flex-column align-items-start justify-content-end friendListTextId">
                    {lastBetTeam ? (<p className="m-0 textWhite friendListTextSpread"><span className="textGold">Last Bet: </span>{lastBetTeam} | {lastBetSpread}</p>) : (<p className="m-0 textWhite friendListTextSpread"><span className="textGold">Last Bet: </span> Zilch</p>)}
                    <p className="m-0 textWhite friendListTextSpread"><span className="textGold">Open Challenges:</span> ({versus.length})</p>
                </div>
            </>
        )
    }

    //Open Friend Modal
    const handleShow = (event) => {

        setFriendIdModal(event.currentTarget.value);

        setShow('t')
    };
    //Close Friend Modal
    const handleClose = () => setShow('');

    return (
        <div className="container">
            <div className="row titleLowerBorder">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            {list ? (
                                <Link to="#" className="friendRequestLink m-0 align-self-end" onClick={switchList}>Friend List</Link>
                            ) : (
                                <Link to="#" className="friendRequestLink m-0 align-self-end" onClick={switchList}>Friend Requests (<span className="textGreen">{friendRequests}</span>)</Link>
                            )}
                            <p className="hideText">|||</p>
                            <Link className="navStyleNU" to="#" onClick={closeFriendList}>X</Link>
                        </div>
                    </div>
                    <div className="row d-flex m-2 flex-wrap">
                        <div className="col-6 friendsText">
                            <div className="row d-flex flex-column align-items-start">
                                {list ? (<p className="m-0"><b>Friend Requests</b></p>) : (<p className="m-0"><b>Friends List</b></p>)}
                            </div>
                        </div>
                        <div className="col-6 d-flex justify-content-end align-content-end p-1">
                            {/* Turns Button Gray when it is clicked and back to Gray when +add friend button is clicked */}
                            {addAFriend ? (
                                <button className="styleBtns addButtonTextG" onClick={showAddAFriend}>+Add a Friend</button>
                            ) : (
                                <button className="styleBtns addButtonText" onClick={showAddAFriend}>+Add a Friend</button>
                            )}
                        </div>
                    </div>
                    {/* If add button is clicked then show input form to send a friend request */}
                    {addAFriend &&
                        <div className="row d-flex">
                            <InputGroup className="mb-3 searchBar flex-fill">
                                <FormControl
                                    placeholder="Enter Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    name='input'
                                    value={formState.input}
                                    onChange={handleChange}
                                    onKeyDown={onKeyDown}
                                />
                                <button id="basic-addon1" className="searchBarText" onClick={sendFriendRequest}><b>+Add</b></button>
                            </InputGroup>
                        </div>
                    }
                    {/* If Friend Request was sent, then render this div */}
                    {friendRequestSent === 1 &&
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <div className="friendRequestSentDiv">
                                    <p className="friendRequestSendText m-0">Friend Request Sent ✅</p>
                                </div>
                            </div>
                        </div>
                    }
                    {/* If no user was found during friend request submission render this div */}
                    {friendRequestSent === 2 &&
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <div className="friendRequestFailedDiv">
                                    <p className="friendRequestSendText m-0">No User Found</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <br />
            {list ? (
                <>
                    {user.friendRequests.map((friend, index) => (
                        <div key={index}>
                            <div className="row friendListDiv">
                                <div className="col-6 d-flex flex-column align-items-start">
                                    {/* If Specific Friend's request button has been hit, re-render that card showing eith accept
                                    or delete of Friend Request */}
                                    {specificFriendAdded === index ? (
                                        <>
                                            {/* Renders FR Accepted or Denied depending on onClick button */}
                                            {decision ? (
                                                <p className="m-0 friendListTextEmail"><span className="textGreen">Friend Request Accepted</span></p>
                                            ) : (
                                                <p className="m-0 friendListTextEmail"><span className="textRed">Friend Request Denied</span></p>
                                            )}
                                        </>
                                    ) : (
                                        <p className="m-0 friendListTextEmail">{friend.email}</p>
                                    )}
                                </div>
                                {/* Render nothing if specificFriend has been selected, Render Buttons if not selected */}
                                {specificFriendAdded === index ? (<></>) : (
                                    <div className="col-6 d-flex justify-content-end friendListTextId">
                                        <button className="addBtn" onClick={() => addTheFriend(friend._id, index)}>+</button>
                                        <button className="deleteBtn" onClick={() => deleteTheFriendRequest(friend._id, index)}>-</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {/* Render Friend List Div */}
                    {user.friends.map((friend, index) => (
                        <button className="noBtnStyle2" key={index} onClick={handleShow} value={friend._id}>
                            <div className="row friendListDiv">
                                {renderAFriend(friend, index)}
                            </div>
                        </button>
                    ))}
                </>
            )}
            {/* Modal to handle specific friend when friend card is clicked */}
            <Modal show={show} onHide={handleClose} className="modalCard p-0">
                <FriendModal user={user} friendIdModal={friendIdModal} handleClose={handleClose} cryptoPrice={cryptoPrice} />
            </Modal>
        </div>
    )
}

export default FriendList;