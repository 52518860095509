import { gql } from '@apollo/client';

export const ADD_USER = gql`
mutation addUser($firstName: String!, $lastName: String!, $email: String!, $password: String!) {
  addUser(firstName: $firstName, lastName: $lastName, email: $email, password: $password) {
    _id
    firstName
    lastName
    email
    accountValue
  }
}
`

export const LOGIN_USER = gql`
mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
        firstName
        lastName
      }
    }
  }
`

export const UPDATE_USER = gql`
mutation UPDATE_USER($id: ID!, $email: String, $password: String, $firstName: String, $lastName: String, $profilePic: String){
  updateUser(userId: $id, email: $email, password: $password, firstName: $firstName, lastName: $lastName, profilePic: $profilePic){
    _id
  }
}`

export const STORE_PUB_KEY = gql `
mutation STORE_PUB_KEY($id: ID!, $pubKey: String!) {
  storePubKey(userId: $id, pubKey: $pubKey) {
    _id
  }
}`

export const STORE_WALLET_VALUE = gql `
mutation STORE_WALLET_VALUE($id: ID!, $walletAmount: Float!) {
  storeWalletValue(userId: $id, walletAmount: $walletAmount) {
    accountValue
  }
}`

export const REVOKE_TOKENS = gql `
mutation REVOKE_TOKENS($id: ID!){
  revokeTokens(userId: $id){
    _id
  }
}`


export const ADD_BET = gql `
mutation ADD_NEW_BET($id: ID!, $riskAmount: Float!, $toWinAmount: Float!, $between: [PlayersA!]!, $ticket: [TicketA!]!) {
  addBet(userId: $id, riskAmount: $riskAmount, toWinAmount: $toWinAmount, between: $between, ticket: $ticket){
    _id
    riskAmount
    toWinAmount
    between{
      player1 {
        email
        for
      }
      player2 {
        email
        for
      }
    }
   ticket {
    type
    date
    league
    game
    team
    spread
    odds
    }
  }
}
`

export const CHALLENGE_BET = gql `
mutation CHALLENGE_BET($id: ID!, $riskAmount: Float!, $toWinAmount: Float!, $between: [PlayersA!]!, $ticket: [TicketA!]!, $email: String!) {
  challengeBet(userId: $id, riskAmount: $riskAmount, toWinAmount: $toWinAmount, between: $between, ticket: $ticket, email: $email){
    _id
    riskAmount
    toWinAmount
    between{
      player1 {
        email
        for
      }
      player2 {
        email
        for
      }
    }
   ticket {
    type
    date
    league
    game
    team
    spread
    odds
    }
  }
}`

export const ACCEPT_CHALLENGE = gql `
mutation ACCEPT_CHALLENGE($userId: ID!, $friendId: ID!, $betId: ID!){
  acceptChallenge (userId: $userId, friendId: $friendId, betId: $betId){
    _id
  }
}`

export const DELETE_CHALLENGE = gql `
mutation DELETE_CHALLENGE($userId: ID!, $friendId: ID!, $betId: ID!){
  deleteChallenge (userId: $userId, friendId: $friendId, betId: $betId){
    _id
  }
}`

export const DELETE_PLACED_BET = gql `
mutation DELETE_PLACED_BET($forEmail: String!, $againstEmail: String!, $betId: ID!){
  deletePlacedBetViaEmail(forEmail: $forEmail, againstEmail: $againstEmail, betId: $betId){
    _id
  }
}`

export const REVERSE_TRANSACTION = gql `
mutation REVERSE_TRANSACTION($id: ID!, $sol: Float!){
  deleteTransaction (userId: $id, sol: $sol){
    _id
  }
}`

export const DELETE_TRANSACTION_VIA_EMAIL = gql `
mutation DELETE_TRANSACTION_VIA_EMAIL($email: String!, $sol: Float!){
  deleteTransactionViaEmail(email: $email, sol: $sol){
    _id
  }
}`

export const REMOVE_EXPIRED_CHALLENGES = gql `
mutation REMOVE_EXPIRED_CHALLENGES($userId: ID!){
  removeExpiredChallenges(userId: $userId){
    _id
  }
}`

export const WIN_BET = gql `
mutation WIN_BET($id: ID!, $betId: String!, $winAmount: Float!) {
  winBet(userId: $id, betId: $betId, winAmount: $winAmount){
      _id
  }
}`

export const LOSE_BET = gql `
mutation LOSE_BET($id: ID!, $betId: String!, $lostAmount: Float!) {
  loseBet(userId: $id, betId: $betId, lostAmount: $lostAmount){
      _id
  }
}`

export const TIE_BET = gql`
mutation TIE_BET($id: ID!, $toWinAmount: Float!, $ticket: [TicketA!]!){
  tieBet (betId: $id, toWinAmount: $toWinAmount, ticket: $ticket){
    _id
  }
}`

export const GET_CRYPTO_PRICE = gql `
mutation GET_CRYPTO_PRICES($id: ID!){
  updateCryptoPrice (id: $id){
    _id
    solana
  }
}
`

export const SEND_FRIEND_REQUEST = gql `
mutation SEND_FRIEND_REQUEST($userId: ID!, $email: String!) {
  addFriendRequest(userId: $userId, email: $email){
    friends{
      _id
    }
  }
}`

export const ADD_FRIEND = gql `
mutation ADD_FRIEND($userId: ID!, $friendId: ID!) {
  addFriend(userId: $userId, friendId: $friendId){
    friends{
      _id
    }
  }
}`

export const DELETE_FRIEND_REQUEST = gql `
mutation DELETE_FRIEND_REQUEST($userId: ID!, $friendId: ID!) {
  deleteFriendRequest(userId: $userId, friendId: $friendId){
    friends{
      _id
    }
  }
}`

export const DELETE_A_FRIEND = gql `
mutation DELETE_A_FRIEND($userId: ID!, $friendId: ID!){
  deleteAFriend (userId: $userId, friendId: $friendId){
    _id
  }
}`