
const editTieBet = (placedBet, ticketNumber) => {

    //Turns a readable object into a writable object
    let mutatedTicket = JSON.stringify(placedBet.ticket);


    //Loop through and remove typename for each object in the array
    for (let i = 0; i < placedBet.ticket.length; i++) {
        mutatedTicket = mutatedTicket.replace('"__typename":"Ticket",', '');
    }

    // Parse back into an object that can be read by Graphql
    let newTicket = JSON.parse(mutatedTicket);

    //Delete unwanted data in the string - specifically the "betId"
    let key = "betId"
    for (let k = 0; k < newTicket.length; k++) {
        delete newTicket[k][key]
    }

    //Accumulator to carry over additional odds
    let accu = 1;

    //Decimal variable to turn American Odds into Decimal Odds
    let decimal;

    //Remove the Ticket that has tied
    newTicket.splice(ticketNumber, 1);

    //For loop to add up remaining ticket odds
    for (let i = 0; i < newTicket.length; i++) {

        let odds = parseInt(newTicket[i].odds);

        if (Math.sign(odds) === 1) {
            decimal = (odds / 100) + 1
        }

        if (Math.sign(odds) === -1) {
            decimal = 1 - (100 / odds)
        }

        accu = accu * decimal;
    }

    //Save the new toWinAmount
    let newToWinAmount = (placedBet.riskAmount * accu) - placedBet.riskAmount;

    //Store Information into a newBet Object that is returned
    let newBet = {
        _id: placedBet._id,
        toWinAmount: newToWinAmount,
        ticket: newTicket
    }

    //Return newBet object to replace existing bet in the database
    return newBet;


    return;

}

export default editTieBet;