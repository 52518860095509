import { NFLteams }  from "./FootballTeams";
import { MLBteams }  from './BaseballTeams';
import { NBAteams } from './BasketBallTeams';
import { EPLteams }  from './SoccerTeams';
import { NHLteams } from './HockeyTeams';
import { NCAABteams } from "./NCAABTeams";
import  generalSearch  from "./GeneralSearch";

const allSports = NFLteams.concat(MLBteams).concat(NBAteams).concat(EPLteams).concat(NHLteams).concat(NCAABteams).concat(generalSearch);

const allSearch = (input) => {

    //console.log(NBAteams);

    let response = allSports.filter(word => word == input)

    response = response[0];
    
    return response
}

const generalSearchCheck = (input) => {
    
    let response = generalSearch.filter(word => word == input)

    if(response[0]){
        return true
    }

    return false;
}

const selectionMade = (input) => {
    
    let regex = `/^${input}$/`
    // let response = allSports.match(exactMatch)
    let response = allSports.indexOf(input);

    console.log(response);
    

    if(response){
        return true;
    } else {
        return false;
    }
}
            

export { allSearch, generalSearchCheck, selectionMade, allSports } ;