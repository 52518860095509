import React from 'react';
import MiniTicketsChallenges from '../MiniTicketsChallenges';

const Challenges = ({ user, friend, refetch }) => {

    let versus = [];

    for (let i = 0; i < friend.challengeBets.length; i++) {
        if (friend.challengeBets[i].between[0].player2[0].email === user.email || friend.challengeBets[i].between[0].player1[0].email === user.email) {
            versus.push(friend.challengeBets[i])
        }
    }

    const renderChallenges = (bet) => {

        let side;

        if (bet.between[0].player1[0].email == user.email) {

            side = 'For'

            return (
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8">
                        <br />
                        <MiniTicketsChallenges user={user} friend={friend} bet={bet} side={side} refetch={refetch} />
                    </div>
                </div>
            )
        } else {

            side = "Against"

            return (
                <div className="row">
                    <div className="col-8">
                        <br />
                        <MiniTicketsChallenges user={user} friend={friend} bet={bet} side={side} refetch={refetch} />
                    </div>
                    <div className="col-4"></div>
                </div>
            )
        }

    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="row textWhite font20 m-0">Challenges:</div>
                {versus.map((challenge, index) => (
                    <div key={index}>
                        {renderChallenges(challenge)}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Challenges;