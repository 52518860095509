import React, { useState } from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import TeamSearching from '../TeamSearching';

import { Link } from 'react-router-dom';

import Auth from '../../utils/auth';

function Homepage({ inputBetween, setInputBetween, cryptoPrice }) {

    //CSS Styling Did not Work, so using document.body to force the background page color to rgb(26, 24, 24) coloring
    document.body.style.backgroundColor = "rgb(26, 24, 24)";

    //Form States For Storing Dynamic Variables
    //Set a State Variable to change the Search Functionality
    const [handleSearch, setHandleSearch] = useState('t')

    //If Auth is logged in then check to see if token is expired. If it is then log them out. Need to wrap it in an If Statement or it crashes
    if(Auth.loggedIn()){
        if(Auth.isTokenExpired(localStorage.getItem('id_token'))){
            Auth.logout();
        } 
    }
    
    //Handle Input Form Change
    const handleChange = (event) => {
        event.preventDefault();

        const { value } = event.target;

        setInputBetween(value);

    }

    //When Enter is hit set the Search Input to Local Storage. 
    //The page refreshes, so it's important to store it into Local Storage, so the search Page
    //can store it into the UseEffect on reload/redirect.
    const onKeyDown = async (event) => {

        let url = '/search'

        if (Auth.loggedIn()) {

            url = `/search/${Auth.getContractor().data._id}`;
        }

        if (event.key === 'Enter') {

            localStorage.setItem('search', inputBetween);
            console.log(inputBetween);
            window.location.assign(url);
        }
    }

    //This does the same function as the Enter Button, but with the Go Button (currently a target)
    const go = async (event) => {
        let url = '/search'

        if (Auth.loggedIn()) {

            url = `/search/${Auth.getContractor().data._id}`;
        }

        localStorage.setItem('search', inputBetween);
        console.log(inputBetween);
        window.location.assign(url);
    }

    //This handles changing the type of seach the user wants to select.
    //Refined search bar has not been built out yet.
    const changeSearch = (type) => {
        if(type === "quick"){setHandleSearch("t")};
        if(type === "refined"){setHandleSearch("")};
    }

    //Log user out
    const logout = () => {
        Auth.logout();
    }

    return (

        <div className="viewPort center backdrop">
            <div className="jumbotron cardBorder">
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        {/* Div Containing Title, Info, Search Bar, & Nav Buttons */}
                        <div className="d-flex flex-column align-items-center">
                            <div className="d-flex align-items-start m-0 titleBorder">
                                <p className="title1 m-0"><b>Rush</b></p>
                                <p className="title2 m-0"> V2</p>
                            </div>
                            <div className="m-1">
                                <p className="tag-line m-0">Sports Betting Made Simple</p>
                                {/* <p className="textWhite m-0">{cryptoPrice}</p> */}
                            </div>
                            <div className="d-flex searchMargin">
                                {/* <p className={handleSearch ? ("m-1 goldT hoverText") : ("m-1 grayT hoverText")} onClick={() => changeSearch("quick")}>Quick Search</p>
                                <p className="m-1 grayT">|</p>
                                <p className={handleSearch ? ("m-1 grayT hoverText") : ("m-1 goldT hoverText")} onClick={() => changeSearch("refined")}>Refined Search</p> */}
                                <p className="goldT">___________</p>
                            </div>
                            {/* When True shows quick search. When False shows the refined Search Bar */}
                            {handleSearch ? (
                                <>
                                    <InputGroup className="searchBar">
                                        <FormControl
                                            placeholder="Enter A Sport or Team..."
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            name='input'
                                            value={inputBetween}
                                            onChange={handleChange}
                                            onKeyDown={onKeyDown}
                                        />
                                        <button id="basic-addon1" className="searchBarText" onClick={go}>{`Search »`}</button>
                                    </InputGroup>
                                    {inputBetween ? (
                                        <TeamSearching inputBetween={inputBetween} setInputBetween={setInputBetween} />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className="propText m-2">Feature Not Available Yet</div>
                                </>
                            )}
                        </div>
                    </div>
                    {/* Links to different Pages */}
                    {/* Logged In - Dashboard & Logout; Logged Out - Login & Sign Up */}
                    <div className="row">
                        <div className="col-12 mt-3 d-flex justify-content-center align-items-start">
                            {Auth.loggedIn() ? (
                                <>
                                    <Link className="navStyle" to={`/dashboard/${Auth.getContractor().data._id}`}>Dashboard</Link>
                                    <Link className="navStyle" to="#" onClick={async () => await logout()}>Logout</Link>
                                </>
                            ) : (

                                <>
                                    <Link className="navStyle" to="/login">Login</Link>
                                    <Link className="navStyle" to="/signUp">Sign Up</Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homepage;