import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
query GET_ALL_USERS {
  users {
      _id
    firstName
    lastName
    email
    accountValue
    walletAuthorized
    placedBets {
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
      ticket {
          type
          betId
          date
          league
          game
          team
          spread
          odds
      }
    }
    challengeBets {
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
      ticket {
        type
        betId
        date
        league
        game
        team
        spread
        odds
      }
    }
    wonBets {
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
      ticket {
          type
          betId
          date
          league
          game
          team
          spread
          odds
      }
    }
    lostBets {
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
      ticket {
          type
          betId
          date
          league
          game
          team
          spread
          odds
      }
    }
    tieBets{
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
      ticket {
          type
          betId
          date
          league
          game
          team
          spread
          odds
      }
    }
    friends {
      _id
    }
    friendRequests{
      _id
      email
      firstName
      lastName
    }
    messages{
      comments{
        comment
      }
    }
    }
}
`

export const GET_ONE_USER = gql`
query GET_ONE_USER ($id: ID!) {
  user(userId: $id) {
  	_id
    firstName
    lastName
    email
    password
    accountValue
    walletAuthorized
    cryptoPubKey
    placedBets {
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
      ticket {
          type
          betId
          date
          league
          game
          team
          spread
          odds
      }
    }
    challengeBets {
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
      ticket {
        type
        betId
        date
        league
        game
        team
        spread
        odds
      }
    }
    wonBets {
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
      ticket {
          type
          betId
          date
          league
          game
          team
          spread
          odds
      }
    }
    lostBets {
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
      ticket {
          type
          betId
          date
          league
          game
          team
          spread
          odds
      }
    }
    tieBets{
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
      ticket {
          type
          betId
          date
          league
          game
          team
          spread
          odds
      }
    }
    friends {
      _id
      firstName
      lastName
      email
      placedBets {
      _id
      riskAmount
      toWinAmount
      ticket {
          type
          betId
          date
          league
          game
          team
          spread
          odds
        }
      }
      challengeBets {
      _id
      riskAmount
      toWinAmount
      between {
        player1 {
          email
          for
        }
        player2 {
          email
          for
        }
      }
        ticket {
          type
          betId
          date
          league
          game
          team
          spread
          odds
        }
      }
      wonBets {
        _id
      }
      lostBets {
        _id
      }
    }
    friendRequests{
      _id
      email
      firstName
      lastName
    }
    messages{
      comments{
        comment
      }
    }
    }
}`

export const GET_USER_EMAILS = gql `
query GET_USER_EMAILS {
  userEmails {
    email
  }
}`