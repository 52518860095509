const generalSearch = [
    'Football',
    'football',
    'NFL',
    'Nfl',
    'nfl',
    'Baseball',
    'baseball',
    'MLB',
    'mlb',
    'Mlb',
    'Basketball',
    'basketball',
    'NBA',
    'nba',
    'Nba',
    'Soccer',
    'soccer',
    'Futbol',
    'futbol',
    'EPL',
    'epl',
    'Epl',
    'Hockey',
    'NHL',
    'Nhl',
    'nhl',
    'NCAAB',
    'NCAA Basketball',
]

export default generalSearch