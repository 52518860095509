import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ONE_USER } from '../../utils/queries';
import { DELETE_A_FRIEND } from '../../utils/mutations';
import MiniTicketsFriends from '../MiniTicketsFriends';
import Challenges from '../Challenges';

import { Link } from 'react-router-dom';

const FriendModal = ({ user, friendIdModal, handleClose, cryptoPrice }) => {

    const [showChallenges, setShowChallenges] = useState('')

    const [ deleteAFriend ] = useMutation(DELETE_A_FRIEND);

    const { loading, data, refetch } = useQuery(GET_ONE_USER, {
        variables: { id: friendIdModal }
    });
    let friend = data?.user || ''

    if (loading) {
        return (
            <div className="propText">Awaiting Data</div>
        )
    }

    let friendWins = friend.wonBets?.length || 0;
    let friendLosses = friend.lostBets?.length || 0;
    let friendTies = friend.tieBets?.length || 0;
    let versus = [];

    let friendRecord = `${friendWins}/${friendLosses}/${friendTies}`;

    //Loop through friends challenges to determine if any of the challenges are against the current user
    for (let i = 0; i < friend.challengeBets.length; i++) {

        if (friend.challengeBets[i].between[0].player2[0].email === user.email || friend.challengeBets[i].between[0].player1[0].email === user.email) {
            //Store these challenges into an array
            versus.push(friend.challengeBets[i])
        }
    }

    const toggleChallenges = () => setShowChallenges(!showChallenges);

    const deleteFriend = async() => {

        try {

            await deleteAFriend({
                variables: {
                    userId: user._id,
                    friendId: friend._id
                }
            })

            window.location.reload();

        } catch(e){
            console.log(e);
        }
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title className="textWhite">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="12">
                                <div>{friend.firstName} {friend.lastName}</div>
                                <div className="textWhite font16">Record: {friendRecord}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Title>
                {showChallenges ? (
                    <Link to="#" className='navStyleNU' onClick={toggleChallenges}>
                        <div>
                            <span className="textGold mobileFont">See Friends Bets</span>
                        </div>
                    </Link>
                ) : (
                    <Link to="#" className='navStyleNU' onClick={toggleChallenges}>
                        <div>
                            <span className="textGold mobileFont">See Challenges (<span className="textGreen">{versus.length}</span>)</span>
                        </div>
                    </Link>
                )}
                
            </Modal.Header>
            <Modal.Body>
                {showChallenges ? (
                    <div className="container-fluid p-0 fakeFooterMargin">
                        <Challenges user={user} friend={friend} refetch={refetch} />
                    </div>
                ) : (
                    <>
                        <div className="textWhite font20">{friend.firstName}'s Placed Bets:</div>
                        <div className="container-fluid p-0 fakeFooterMargin">
                            {friend.placedBets.length >= 1 ? (
                                <>
                                    {friend.placedBets.map((placedBet, index) => (
                                        <MiniTicketsFriends friend={friend} bet={placedBet} key={index} cryptoPrice={cryptoPrice} refetch={refetch} />
                                    ))}
                                </>
                            ) : (
                                <div className="textGold m-2">No Recent Bets</div>
                            )}
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <Link className="deactivateLink" to="#" onClick={deleteFriend}>
                    Remove Friend
                </Link>
                <Link className="addLinkBtn" to="#" onClick={handleClose}>
                    Close
                </Link>
            </Modal.Footer>
        </>
    )
}

export default FriendModal;