import React from 'react';

import Parlay from '../Parlay';

function MiniTickets({ user, bet }) {

    let side;
    let opponent;

    if (bet.between[0].player1[0].email == user.email) {

        side = "For"
        opponent = bet.between[0].player2[0].email

        if(opponent == 'rushv2'){
            opponent = 'RushV2'
        }

    } else {

        side = "Against"
        opponent = bet.between[0].player1[0].email
    }


    return (
        <>
            {bet.ticket.length < 2 ? (
                <div className="d-flex justify-content-between">
                    <div className="tinyBorder"><b>Single Bet</b></div>
                    <div className={side == "Against" ? ("tinyBorder bg-against") : ("tinyBorder bg-for")}><b>{side}</b></div>
                </div>
            ) : (
                <div className="d-flex justify-content-between">
                    <div className="tinyBorder"><b>Parlay</b></div>
                    <div className={side == "Against" ? ("tinyBorder bg-against") : ("tinyBorder bg-for")}><b>{side}</b></div>
                </div>
            )}
            {bet.ticket.map((ticket, index) => (
                <Parlay ticket={ticket} key={index} />
            ))}
            {side == "For" ? (
                <div className="row m-0 bg-card">
                    <div className="col-4 tinyStyle">{bet.riskAmount} SOL</div>
                    <div className="col-4 tinyStyle">to Win</div>
                    <div className="col-4 tinyStyle">{bet.toWinAmount} SOL</div>
                </div>
            ) : (
                <div className="row m-0 bg-card">
                    <div className="col-4 tinyStyle">{bet.toWinAmount} SOL</div>
                    <div className="col-4 tinyStyle">to Win</div>
                    <div className="col-4 tinyStyle">{bet.riskAmount} SOL</div>
                </div>
            )}
            <div className="row m-0">
                <p className="textWhite">vs. {opponent}</p>
            </div>
        </>
    )
}

export default MiniTickets;