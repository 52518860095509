import React from 'react';
import { NFLQuery } from '../../utils/SportsArrays/FootballTeams';
import { NBAQuery } from '../../utils/SportsArrays/BasketBallTeams';
import { EPLQuery } from '../../utils/SportsArrays/SoccerTeams';
import { MLBQuery } from '../../utils/SportsArrays/BaseballTeams';
import { NHLQuery } from '../../utils/SportsArrays/HockeyTeams';
import { NCAABQuery} from '../../utils/SportsArrays/NCAABTeams';


function Odds({ result }) {

    let h2hteam1;
    let h2hteam2;
    let h2hteam1Price;
    let h2hteam2Price;
    let drawPrice;
    let spteam1Price;
    let spteam2Price;
    let spteam1Spread;
    let spteam2Spread;
    let underPrice;
    let overPrice;
    let underPoints
    let overPoints;
    let searchInput = localStorage.getItem('search')
    const selectBookmaker = 'betus';

    // Add a Plus to the Odds data
    function addAPlus(data) {
        if (JSON.stringify(data).search("-") == 0) {
            return data = data;
        } else {
            return data = `+${data}`
        }
    }

    if (NFLQuery(searchInput)) {
        searchInput = 'football'
    };

    if (NBAQuery(searchInput)) {
        searchInput = 'basketball'
    };

    if (EPLQuery(searchInput)) {
        searchInput = 'soccer'
    };

    if (NHLQuery(searchInput)) {
        searchInput = 'hockey'
    };

    if (MLBQuery(searchInput)) {
        searchInput = 'baseball'
    };

    if(NCAABQuery(searchInput)){
        searchInput = 'ncaab'
    }

    if (searchInput === 'football' ||
        searchInput === 'basketball' ||
        searchInput === 'baseball' ||
        searchInput === 'hockey' ||
        searchInput === 'ncaab' ) {

        for (let i = 0; i < result.bookmakers.length; i++) {
            if (result.bookmakers[i].key === selectBookmaker) {

                h2hteam1 = result.bookmakers[i].markets[0].outcomes[0].name || '';
                h2hteam2 = result.bookmakers[i].markets[0].outcomes[1].name || '';
                h2hteam1Price = result.bookmakers[i].markets[0].outcomes[0].price || '';
                h2hteam2Price = result.bookmakers[i].markets[0].outcomes[1].price || '';
                spteam1Price = result.bookmakers[i].markets[1]?.outcomes[0].price || '';
                spteam2Price = result.bookmakers[i].markets[1]?.outcomes[1].price || '';
                spteam1Spread = result.bookmakers[i].markets[1]?.outcomes[0].point || '';
                spteam2Spread = result.bookmakers[i].markets[1]?.outcomes[1].point || '';
                underPrice = result.bookmakers[i].markets[2]?.outcomes[1].price || 'n/a';
                overPrice = result.bookmakers[i].markets[2]?.outcomes[0].price || 'n/a';
                underPoints = result.bookmakers[i].markets[2]?.outcomes[1].point || '';
                overPoints = result.bookmakers[i].markets[2]?.outcomes[0].point || '';

                h2hteam1Price = addAPlus(h2hteam1Price);
                h2hteam2Price = addAPlus(h2hteam2Price);
                spteam1Price = addAPlus(spteam1Price);
                spteam2Price = addAPlus(spteam2Price);
                spteam1Spread = addAPlus(spteam1Spread);
                spteam2Spread = addAPlus(spteam2Spread);
                underPrice = addAPlus(underPrice);
                overPrice = addAPlus(overPrice);
            }
        }

        return (
            <div className="row m-0">
                <div className="col-12">
                    <div className="row teamDiv">
                        <div className="col-1"></div>
                        <div className="col-4 propTeamText">{h2hteam1}</div>
                        <div className="col-2"><p className="propTextVS">vs.</p></div>
                        <div className="col-4 propTeamText">{h2hteam2}</div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row spreadOut">
                        {/* <div className="col-1 propText">S</div> */}
                        <div className="col-6 d-flex justify-content-center">
                            <p className="propText space">{spteam1Spread}</p>
                            <p className="propText space">|</p>
                            <p className="propText space">{spteam1Price}</p>
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                            <p className="propText space">{spteam2Spread}</p>
                            <p className="propText space">|</p>
                            <p className="propText space">{spteam2Price}</p>
                        </div>
                        {/* <div className="col-1 propText"></div> */}
                    </div>
                    <div className="row spreadOut">
                        {/* <div className="col-1 propText">ML</div> */}
                        <div className="col-6 propText">{h2hteam1Price}</div>
                        <div className="col-6 propText">{h2hteam2Price}</div>
                        {/* <div className="col-1 propText"></div> */}
                    </div>
                    <div className="row spreadOut">
                        {/* <div className="col-1 propText"><p>OU</p></div> */}
                        <div className="col-6 d-flex justify-content-center">
                            <p className="propText">O</p>
                            <p className="propText">{` ${overPoints}`}</p>
                            <p className="propText space">|</p>
                            <p className="propText space">{overPrice}</p>
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                            <p className="propText">U</p>
                            <p className="propText">{underPoints}</p>
                            <p className="propText space">|</p>
                            <p className="propText space">{underPrice}</p>
                        </div>
                        {/* <div className="col-1 propText"></div> */}
                    </div>
                </div>
            </div>
        )
    }


    if (searchInput === "soccer") {

        for (let i = 0; i < result.bookmakers.length; i++) {
            if (result.bookmakers[i].key === selectBookmaker) {
                h2hteam1 = result.bookmakers[i].markets[0].outcomes[0].name || '';
                h2hteam2 = result.bookmakers[i].markets[0].outcomes[1].name || '';
                h2hteam1Price = result.bookmakers[i].markets[0].outcomes[0].price || '';
                h2hteam2Price = result.bookmakers[i].markets[0].outcomes[1].price || '';
                drawPrice = result.bookmakers[i].markets[0].outcomes[2]?.price || '';
                spteam1Price = result.bookmakers[i].markets[1]?.outcomes[0].price || '';
                spteam2Price = result.bookmakers[i].markets[1]?.outcomes[1].price || '';
                spteam1Spread = result.bookmakers[i].markets[1]?.outcomes[0].point || '';
                spteam2Spread = result.bookmakers[i].markets[1]?.outcomes[1].point || '';
                underPrice = result.bookmakers[i].markets[2]?.outcomes[1].price || 'n/a';
                overPrice = result.bookmakers[i].markets[2]?.outcomes[0].price || 'n/a';
                underPoints = result.bookmakers[i].markets[2]?.outcomes[1].point || '';
                overPoints = result.bookmakers[i].markets[2]?.outcomes[0].point || '';
                
                if(spteam1Spread === ''){
                    spteam1Spread = 0;
                }

                if(spteam2Spread === ''){
                    spteam2Spread = 0;
                }

                h2hteam1Price = addAPlus(h2hteam1Price);
                h2hteam2Price = addAPlus(h2hteam2Price);
                drawPrice = addAPlus(drawPrice)
                spteam1Price = addAPlus(spteam1Price);
                spteam2Price = addAPlus(spteam2Price);
                spteam1Spread = addAPlus(spteam1Spread);
                spteam2Spread = addAPlus(spteam2Spread);
                underPrice = addAPlus(underPrice);
                overPrice = addAPlus(overPrice);
            }

        }

        return (
            <div className="row m-0">
                <div className="col-12">
                    <div className="row teamDiv">
                        <div className="col-1"></div>
                        <div className="col-4 propTeamText">{h2hteam1}</div>
                        <div className="col-2"><p className="propTextVS">vs.</p></div>
                        <div className="col-4 propTeamText">{h2hteam2}</div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row spreadOut">
                        {/* <div className="col-1 propText">S</div> */}
                        <div className="col-6 d-flex justify-content-center">
                            <p className="propText space">{spteam1Spread}</p>
                            <p className="propText space">|</p>
                            <p className="propText space">{spteam1Price}</p>
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                            <p className="propText space">{spteam2Spread}</p>
                            <p className="propText space">|</p>
                            <p className="propText space">{spteam2Price}</p>
                        </div>
                        {/* <div className="col-1 propText"></div> */}
                    </div>
                    <div className="row spreadOut">
                        {/* <div className="col-1 propText">ML</div> */}
                        <div className="col-6 propText">{h2hteam1Price}</div>
                        <div className="col-6 propText">{h2hteam2Price}</div>
                        <div className="vHidden">{drawPrice}</div>
                        {/* <div className="col-1 propText"></div> */}
                    </div>
                    <div className="row spreadOut">
                        {/* <div className="col-1 propText"><p>OU</p></div> */}
                        <div className="col-6 d-flex justify-content-center">
                            <p className="propText">O</p>
                            <p className="propText">{` ${overPoints}`}</p>
                            <p className="propText space">|</p>
                            <p className="propText space">{overPrice}</p>
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                            <p className="propText">U</p>
                            <p className="propText">{underPoints}</p>
                            <p className="propText space">|</p>
                            <p className="propText space">{underPrice}</p>
                        </div>
                        {/* <div className="col-1 propText"></div> */}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="propText">No Results</div>
    )
}

export default Odds;
