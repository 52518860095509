import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { LOSE_BET, WIN_BET, TIE_BET, DELETE_TRANSACTION_VIA_EMAIL, DELETE_PLACED_BET, REMOVE_EXPIRED_CHALLENGES } from '../../utils/mutations';
import sportsDB from '../../utils/SportsDBAPI';

import moment from 'moment';
import editTieBet from '../../utils/tieBet';

const CheckBets = ({ user, refetch }) => {

    // Mutations to change account Value and place a passed bet into win, loss, or tie.
    const [loseBet] = useMutation(LOSE_BET);
    const [winBet] = useMutation(WIN_BET);
    const [tieBet] = useMutation(TIE_BET);
    const [deleteTransactionViaEmail] = useMutation(DELETE_TRANSACTION_VIA_EMAIL);
    const [deletePlacedBetViaEmail] = useMutation(DELETE_PLACED_BET);
    const [removeExpiredChallenges] = useMutation(REMOVE_EXPIRED_CHALLENGES);

    const addUnderScore = (input) => {
        let feeder = input.split(' ').join('_');
        return feeder;
    }

    const updateDatabaseTieBet = async (newBet, oldBet) => {

        let newTicket = [...newBet.ticket];
        console.log("Entered into updateDatabaseTieBet")
        console.log(newBet, 1);
        console.log(oldBet, 2);
        try {

            //If the newTicket has no more bets, then delete the Ticket & return Money back to For User
            if (newTicket.length === 0) {
                console.log("Entered into newTicket.length === 0")
                try {
                    //Delete the Placed bet from user and friend arrays
                    await deletePlacedBetViaEmail({
                        variables: {
                            forEmail: oldBet.between[0].player1[0].email,
                            againstEmail: oldBet.between[0].player2[0].email,
                            betId: oldBet._id
                        }
                    })
                    console.log("success bet has been deleted")
                } catch (e) {
                    console.log(e);
                    return;
                }

                try {
                    
                    // Send Money (sols) back to person who sent the bet
                    await deleteTransactionViaEmail({
                        variables: {
                            email: oldBet.between[0].player1[0].email,
                            sol: oldBet.riskAmount
                        }
                    })
                    console.log("success - the transaction has been sent back to user")
                    //If opponent is rushv2 than bypass this if statement
                    if (oldBet.between[0].player2[0].email !== "rushv2") {
                        // Send Money (sols) back to person who accepted the bet
                        await deleteTransactionViaEmail({
                            variables: {
                                email: oldBet.between[0].player2[0].email,
                                sol: oldBet.toWinAmount
                            }
                        })
                    }

                } catch (e) {
                    console.log(e);
                }
                console.log("success. All the try catches were successful");

                await refetch();
                return;

            } else {

                await tieBet({
                    variables: {
                        id: oldBet._id,
                        toWinAmount: newBet.toWinAmount,
                        ticket: newTicket
                    }
                })
                console.log("The bet is longer than 1 ticket and the bet has been updated to reflect as such");

                await refetch();
                return;
                
            }

            

        } catch (e) {
            console.log(e);
        }

    }

    const compareTheBets = async () => {

        let gameResults = [];

        let opponent;
        let forBet;

        if(user.placedBets.length === 0){
            console.log("No active Bets");
            return;
        }

        // Looping through each bet in the User.placedBets array
        for (let i = 0; i < user.placedBets.length; i++) {

            console.log(user.placedBets[i]);

            //Variable to keep track if the winner has won all the bets in an array. If winCount matches placedBets.length than it will be a winning ticket
            let winCount = 0;

            //Keep Track if Game is not ready
            let gameIsReady;

            //Looping through each ticket array with each placeBets Array
            for (let k = 0; k < user.placedBets[i].ticket.length; k++) {

                //Variable to check if an array item has a loss attached to it, or is not ready for the bet. This will break the loop if parlay check = 1, and continue if = 0.
                let parlayCheck = 0;


                if (parlayCheck === 1) {
                    break;
                }

                gameIsReady = true;

                //Setting variables for the current date & ticket bet date for comparisson
                let gameTimeBuffer = 2 * 3600
                let extremeTimeBuffer = 4 * 3600;
                const unixTimeBuffer = (Date.now() / 1000) + gameTimeBuffer
                const unixOfBet = moment(user.placedBets[i].ticket[k].date).unix()
                const unixOfBetWithBuffer = moment(user.placedBets[i].ticket[k].date).unix() + extremeTimeBuffer;
                const currentTime = Date.now() / 1000

                // If the Date for any of the tickets is greater than the current date time, the function will exit. 
                //This is a precursor to withold sportsDB API running
                if (unixOfBet > unixTimeBuffer) {
                    console.log("Game has not started");
                    parlayCheck = 1;
                    gameIsReady = false;
                    break;
                }

                // Variables to set up the Sports DB API URL
                let dateOfGame = moment.utc(user.placedBets[i].ticket[k].date).format('YYYY-MM-DD');
                let gameURL = addUnderScore(user.placedBets[i].ticket[k].game);
                let leagueURL = addUnderScore(user.placedBets[i].ticket[k].league);

                //If league equal to NCAAB then change it to the string below
                //This needs to be exact to match SportsDB API request for college mens basketball teams
                if (leagueURL === 'NCAAB') {
                    leagueURL = 'NCAA_Division_I_Basketball_Mens'
                }

                // VARIABLE TO STORE URL WITH LEAGUE DATA - ENABLES US TO QUERY A GAME OBJECT FROM SPORTS DB
                let URL = `https://www.thesportsdb.com/api/v1/json/2/searchfilename.php?e=${leagueURL}_${dateOfGame}_${gameURL}`;
                console.log(URL);

                // AXIOS CALL TO SPORTSDB TO QUERY DATA
                gameResults = await sportsDB(URL);
                console.log(gameResults);

                //This will break out of the loop unless the game has finished. First it checks the returned game object.
                //If the returned game object is false, it checks to the timer. If timer is greater than 3 hours from when game started
                //Then continue the function. The game object failed to return a completed time

                if (gameResults[2].matchStatus !== true) {
                    if (currentTime > unixOfBetWithBuffer) {
                        console.log("Enough time has passed.")
                    } else {
                        console.log("Game has started, but is not finished");
                        parlayCheck = 1;
                        gameIsReady = false;
                        break;
                    }
                }

                // Comparing a bet with Spread
                if (user.placedBets[i].ticket[k].type === "Spread") {

                    console.log("Inside The Spread Operator");

                    // Looping through game Results Object to match which team the ticket bet for
                    for (let j = 0; j < gameResults.length; j++) {

                        // Finding the correct team with the gameResults object to compare against bet
                        if (user.placedBets[i].ticket[k].team === gameResults[j].team) {

                            // Checking to see if ticket spread is positive or negative since functions will have to be different to calculate if the bet is won, lost, or tied
                            if (Math.sign(user.placedBets[i].ticket[k].spread) === 1) {

                                // Logic to compare spread if bettor took a positive spread
                                if (gameResults[j].spread > -(user.placedBets[i].ticket[k].spread)) {

                                    winCount += 1;
                                    console.log(`Game spread was ${gameResults[j].spread}. Win with ${user.placedBets[i].ticket[k].spread}`);
                                    console.log(1);


                                } else if (Math.abs(gameResults[j].spread) === user.placedBets[i].ticket[k].spread) {

                                    console.log(`Game spread was ${gameResults[j].spread}. Push with ${user.placedBets[i].ticket[k].spread}`);
                                    console.log(2);

                                    //Remove the tie bet and return a newBet object to modify Database
                                    let newBet = editTieBet(user.placedBets[i], k);

                                    //Update the database with the new bet. This refetches and restarts clickIt function
                                    updateDatabaseTieBet(newBet, user.placedBets[i]);

                                } else {

                                    winCount -= 1;
                                    parlayCheck = 1;
                                    console.log(`Game spread was ${gameResults[j].spread}. Loss with ${user.placedBets[i].ticket[k].spread}`);
                                    console.log(3);
                                    break;

                                }
                            } else {
                                // Logic to compare if bettor took a negative spread
                                if (gameResults[j].team === user.placedBets[i].ticket[k].team) {
                                    if (gameResults[j].spread > Math.abs(user.placedBets[i].ticket[k].spread)) {

                                        winCount += 1;
                                        console.log(` Game spread was ${gameResults[j].spread}. Win with ${user.placedBets[i].ticket[k].spread}`);
                                        console.log(4);

                                    } else if (gameResults[j].spread === Math.abs(user.placedBets[i].ticket[k].spread)) {

                                        console.log(` Game spread was ${gameResults[j].spread}. Push with ${user.placedBets[i].ticket[k].spread}`);
                                        console.log(5);

                                        //Remove the tie bet and return a newBet object to modify Database
                                        let newBet = editTieBet(user.placedBets[i], k);

                                        //Update the database with the new bet. This refetches and restarts clickIt function
                                        updateDatabaseTieBet(newBet, user.placedBets[i]);

                                    } else {

                                        winCount -= 1;
                                        parlayCheck = 1;
                                        console.log(`Game spread was ${gameResults[j].spread}. Loss with ${user.placedBets[i].ticket[k].spread}`);
                                        console.log(6);
                                        break;
                                    }
                                }
                            }
                        }
                    }

                }

                // if Money Line

                // Comparing a Straight Up bet or Money Line Bet
                if (user.placedBets[i].ticket[k].type === "Money Line") {

                    console.log("Inside the Money Line Operator");

                    for (let j = 0; j < gameResults.length; j++) {
                        if (user.placedBets[i].ticket[k].team === gameResults[j].team) {

                            if (Math.sign(gameResults[j].spread) === 1) {

                                winCount += 1;
                                console.log("Congratulations the Money Line Bet. You won")
                                console.log(7);

                            } else if (gameResults[j].spread === 0) {

                                console.log("It's a Push!");
                                console.log(8);

                                //Remove the tie bet and return a newBet object to modify Database
                                let newBet = editTieBet(user.placedBets[i], k);

                                //Update the database with the new bet. This refetches and restarts clickIt function
                                updateDatabaseTieBet(newBet, user.placedBets[i]);

                            } else {

                                winCount -= 1
                                parlayCheck = 1;
                                console.log("You lost the money line Bet")
                                console.log(9)
                                break;

                            }
                        }
                    }
                }

                // Logic to compare If Over won, loss, or push
                if (user.placedBets[i].ticket[k].type === "Over") {

                    console.log("Inside the OVER operator");

                    if (user.placedBets[i].ticket[k].spread < gameResults[2].totalScore) {

                        winCount += 1;
                        console.log("You won the OVER Bet");
                        console.log(10);

                    } else if (user.placedBets[i].ticket[k].spread === gameResults[2].total) {

                        console.log("Over PUSH")
                        console.log(11);

                        //Remove the tie bet and return a newBet object to modify Database
                        let newBet = editTieBet(user.placedBets[i], k);

                        //Update the database with the new bet. This refetches and restarts clickIt function
                        updateDatabaseTieBet(newBet, user.placedBets[i]);

                    } else {

                        winCount -= 1
                        parlayCheck = 1;
                        console.log("You Lost the Over Bet");
                        console.log(12)
                        break;
                    }
                }

                // Logic to compare if Under won, loss, or push
                if (user.placedBets[i].ticket[k].type === "Under") {

                    console.log("Inside the UNDER Operator");

                    if (user.placedBets[i].ticket[k].spread > gameResults[2].totalScore) {

                        winCount += 1;
                        console.log("You won the UNDER Bet");
                        console.log(13);

                    } else if (user.placedBets[i].ticket[k].spread === gameResults[2].total) {

                        console.log("Under PUSH")
                        console.log(14);

                        //Remove the tie bet and return a newBet object to modify Database
                        let newBet = editTieBet(user.placedBets[i], k);

                        //Update the database with the new bet. This refetches and restarts clickIt function
                        updateDatabaseTieBet(newBet._id, user.placedBets[i]);

                    } else {

                        winCount -= 1
                        parlayCheck = 1;
                        console.log("You Lost the UNDER Bet");
                        console.log(15)
                        break;

                    }
                }

            }

            //Get the person who the user is against
            if (user.placedBets[i].between[0].player1[0].email === user.email) {
                opponent = user.placedBets[i].between[0].player2[0].email;
                forBet = true;
            } else {
                opponent = user.placedBets[i].between[0].player1[0].email
                forBet = false;
            }

            if (gameIsReady) {
                //If the user is for the bet then it will check if wincount is equal to ticket length
                //Else the user is against the bet and the opposite will happen
                if (forBet) {

                    // If winCount equals the same as ticket length - then the bettor successfully hit their parlay. Else they lost and bet
                    // will be sent to the Losing Array
                    if (winCount === user.placedBets[i].ticket.length) {

                        try {

                            await winBet({
                                variables: {
                                    id: user._id,
                                    betId: user.placedBets[i]._id,
                                    winAmount: user.placedBets[i].toWinAmount

                                },
                            })
                            console.log("Inside the For Bet Winning If Statement");

                        } catch (e) {
                            console.log(e)
                        }
                    } else {

                        try {

                            await loseBet({
                                variables: {
                                    id: user._id,
                                    betId: user.placedBets[i]._id,
                                    lostAmount: user.placedBets[i].riskAmount
                                }
                            })

                            console.log("Inside the For Bet Losing If Statement");

                        } catch (e) {
                            console.log(e)
                        }

                    }
                } else {

                    if (winCount !== user.placedBets[i].ticket.length) {

                        try {

                            await winBet({
                                variables: {
                                    id: user._id,
                                    betId: user.placedBets[i]._id,
                                    winAmount: user.placedBets[i].toWinAmount

                                },
                            })

                            console.log("Inside the Against Win Bet If Statement")

                        } catch (e) {
                            console.log(e)
                        }

                    } else {

                        try {

                            await loseBet({
                                variables: {
                                    id: user._id,
                                    betId: user.placedBets[i]._id,
                                    lostAmount: user.placedBets[i].riskAmount
                                }
                            })

                            console.log("Inside the Against Lose Bet Statement");

                        } catch (e) {
                            console.log(e)
                        }

                    }
                }
            }
        }
    }

    useEffect(async () => {

        const isAuth = user?.walletAuthorized || false;

        if (isAuth) {

            if(user.placedBets.length !== 0){
                console.log("Active Placed Bets");
                await compareTheBets();
            };

            if(user.challengeBets.length !== 0){
                console.log("Active Challenge Bets");
                await removeExpiredChallenges({
                    variables: {
                        userId: user._id
                    }
                });
            };
            
            await refetch();

        } else {
            console.log("User Not Connected. This is for Compare Bets useEffect");
        }

    }, []);


    return (
        <></>
    )

}

export default CheckBets;