import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { useQuery, useMutation } from '@apollo/client';
import { GET_USER_EMAILS } from '../../utils/queries';
import { ADD_USER } from '../../utils/mutations';

import { Link } from 'react-router-dom';

function SignUp() {

    //Formstate to handle Dynamic Changing of Variables
    const [formState, setFormState] = useState({
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordCheck: ''
    })
    const [userExist, setUserExist] = useState(false);
    const [passwordFail, setPasswordFail] = useState(false);
    const [passwordShort, setPasswordShort] = useState(false);
    const [accountCreated, setAccountCreated] = useState(false);
    const [failUpper, setFailUpper] = useState(false);

    //Mutation to Add a New User to Database
    const [addUser] = useMutation(ADD_USER);

    //Query to get All Users
    const { data, refetch } = useQuery(GET_USER_EMAILS);

    const userEmails = data?.userEmails || '';

    //Handle Form State Changes & Update State Variables
    const handleChange = (event) => {
        event.preventDefault()

        const { value, name } = event.target

        setFormState({
            ...formState,
            [name]: value
        })

    }

    //Check to see if there is any uppercase letters in user email
    const isUpper = (email) => {
        return /[A-Z]/.test(email);
    }

    //Handle Form Submit - Check if Passwords are correct - then Add the user.
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        //Reset Variable States when/if User re-submits information
        setUserExist(false);
        setPasswordFail(false);
        setPasswordShort(false);
        setAccountCreated(false);
        setFailUpper(false);

        await refetch();

        //Check to see if User has input any uppercase letters
        const uppercase = isUpper(formState.email);
        
        //If there are any uppercases...
        if(uppercase){
            //Alert User by turning failUpper state variable to true.
            setFailUpper(true);
            return;
        }
        //If everything works return the state back to false, to unrender. 
        setFailUpper(false);

        //For the array of user emails
        for (let i = 0; i < userEmails.length; i++) {
            //Check if the user input matches any of the existing user emails in database
            if (userEmails[i].email === formState.email) {
                //If there is a match. Set User Exist to true to show Div that renders "User Exists"
                setUserExist(true);
                //Reset the specific formstate values for User to re-enter
                setFormState({
                    ...formState,
                    email: '',
                    password: '',
                    passwordCheck: ''
                })
                //Exit function
                return;
            }
        }

        //If everything works return the state back to false, to unrender. 
        setUserExist(false);

        //If password does not match password check...
        if (formState.password !== formState.passwordCheck) {

            //Alert the user by turning state to true
            setPasswordFail(true);

            //If passwords do not match, but are longer than 8 characters. Set password short back to false
            //So the user is not provided with too much information at one time.
            setPasswordShort(false);
            return;
        }
        //If everything works return the state back to false, to unrender. 
        setPasswordFail(false);
        
        //If password length is not equal to or less than 8...
        if (formState.password.length <= 7) {
            //Alert user by turning state to true
            setPasswordShort(true);
            return;
        }
        //If everything works return the state back to false, to unrender. 
        setPasswordShort(false);
        
        //Try catch Statement to Add User to Database
        try {
            
           
            //Mutation to Add User
            await addUser({
                variables: { ...formState }
            })

            //If user is added than display pop up modal saying so!
            setAccountCreated(true);

        } catch (e) {
            console.error(e)
        }

        //Reset Object Strings to unwritten
        setFormState({
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            passwordCheck: ''
        });
    };

    //Close Friend Modal
    const handleClose = () => setAccountCreated(false);

    //Handle Page Reset
    const backToHomepage = () => {
        // If user is added Redirect to Homepage for Login
        window.location.assign('/');
    }

    return (

        <div className="center backdrop">
            <div className="jumbotron jStyle cardBorder">
                <div className="container-fluid">
                    <div className="row">
                        <div className="d-flex flex-column align-items-center">
                            <div className="d-flex align-items-start m-0 titleBorder">
                                <p className="title1 m-0"><b>Rush</b></p>
                                <p className="title2 m-0"> V2</p>
                            </div>
                            <div className="m-1">
                                <p className="tag-line m-0">Sports Betting Made Simple</p>
                            </div>
                            <div className='d-flex align-self-start searchMargin'>
                                <p className="m-1 goldT"></p>
                                <p className="m-1 grayT"></p>
                                <p className="m-1 grayT"></p>
                            </div>
                            <Form>
                                <div className="d-flex justify-content-center m-2">
                                    <div className="d-flex m-0 titleLowerBorder fitIt">
                                        <p className="loginTitle1 m-0"><b>Sign</b></p>
                                        <p className="loginTitle2 m-0">up</p>
                                    </div>
                                </div>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="formTitleText">Enter Email Address:</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="example@gmail.com"
                                        name="email"
                                        value={formState.email}
                                        onChange={handleChange}
                                    />
                                    <div className="d-flex flex-column">
                                        {userExist &&
                                            <Form.Label className="textRed font16 m-0">
                                                User Already Exists. Please choose new email.
                                            </Form.Label>
                                        }
                                        {failUpper &&
                                            <Form.Label className="textRed font16 m-0">
                                                No Uppercase Allowed.
                                            </Form.Label>
                                        }
                                        <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                        </Form.Text>
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="formTitleText">Enter First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="First Name..."
                                        name="firstName"
                                        value={formState.firstName}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="formTitleText">Enter Last Name:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Last Name..."
                                        name="lastName"
                                        value={formState.lastName}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label className="formTitleText">Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={formState.password}
                                        onChange={handleChange} />
                                    {passwordFail &&
                                        <Form.Label className="textRed font16 m-0">
                                            Passwords do not Match!
                                        </Form.Label>
                                    }
                                    {passwordShort &&
                                        <Form.Label className="textRed font16 m-0">
                                            Password must be 8 or more characters.
                                        </Form.Label>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label className="formTitleText">Re-Enter Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        name="passwordCheck"
                                        value={formState.passwordCheck}
                                        onChange={handleChange} />
                                    {passwordFail &&
                                        <Form.Label className="textRed font16 m-0">
                                            Passwords do not Match!
                                        </Form.Label>
                                    }
                                </Form.Group>
                                <button className="styleBtns" onClick={handleFormSubmit}>Sign Up</button>
                                <br />
                            </Form>
                        </div>
                    </div>
                    <div className="row fakeFooterMargin">
                        <div className="col-12 d-flex justify-content-end align-items-start">
                            <p className="loginSignUpText"></p>
                            <Link className="navStyle" to="/login">Login</Link>
                            <Link className="navStyle" to="/">Back</Link>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <Modal show={accountCreated} onHide={handleClose} className="modalCard p-0">
                <Modal.Header className="-d-flex justify-content-center">
                    <Modal.Title className="textWhite">
                        <div className="centerText m-1">Congratulations!</div>
                        <div className="centerText m-1 textGold">Account <u>{`${formState.email}`}</u> was created</div>
                        <div className="centerText m-1">Please Login from Homepage!</div>
                    </Modal.Title>
                </Modal.Header>
                <div className="d-flex justify-content-center m-1">
                    <button className="searchBarText" onClick={backToHomepage}>Homepage!</button>
                </div>
            </Modal>
        </div>
    )
}

export default SignUp;