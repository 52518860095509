const NCAABQuieries = [
    'NCAAB',
    'NCAA Basketball',
    'Gonzaga Bulldogs_NCAAB',
    'Arizona Wildcats_NCAAB',
    `Houston Cougars_NCAAB`,
    'Kentucky Wildcats_NCAAB',
    'Baylor Bears_NCAAB',
    'Kansas Jayhawks_NCAAB',
    'Villanova Wildcats_NCAAB',
    'Tennessee Volunteers_NCAAB',
    'Duke Blue Devils_NCAAB',
    'Texas Tech Red Raiders_NCAAB',
    'Auburn Tigers_NCAAB',
    'Purdue Boilermakers_NCAAB',
    'UCLA Bruins_NCAAB',
    'Illinois Fighting Illini_NCAAB',
    'Texas Longhorns_NCAAB',
    'LSU Tigers_NCAAB',
    'Connecticut Huskies_NCAAB',
    'Iowa Hawkeyes_NCAAB',
    `Saint Mary's Gaels_NCAAB`,
    'Ohio St Buckeyes_NCAAB',
    'Wisconsin Badgers_NCAAB',
    'Alabama Crimson Tidee_NCAAB',
    'Arkansas Razorbacks_NCAAB',
    'Murray St Racers_NCAAB',
    'Providence Friars_NCAAB',
    'USC Trojans_NCAAB',
    'Colorado St Rams_NCAAB',
    'San Francisco Dons_NCAAB',
    'Boise St Broncos_NCAAB',
    'San Diego St Aztecs_NCAAB',
    'Loyola Ramblers_NCAAB',
    'Seton Hall Pirates_NCAAB',
    'Michigan St Spartans_NCAAB',
    'Marquette Golden Eagles_NCAAB',
    'Iowa St Cyclones_NCAAB',
    'Xavier Musketeers_NCAAB',
    'Michigan Wolverines_NCAAB',
    'North Texas Mean Green_NCAAB',
    'Wyoming Cowboys_NCAAB',
    'North Carolina Tar Heels_NCAAB',
    'Virginia Tech Hokies_NCAAB',
    'Memphis Tigers_NCAAB',
    'Wake Forest Demon Deacons_NCAAB',
    'Indiana Hoosiers_NCAAB',
    'Davidson Wildcats_NCAAB',
    'Notre Dame Fighting Irish_NCAAB',
    'SMU Mustangs_NCAAB',
    'Oklahoma Sooners_NCAAB',
    'TCU Horned Frogs_NCAAB',
    'BYU Cougars_NCAAB',
    'Florida Gators_NCAAB',
    'Mississippi St Bulldogs_NCAAB',
    'VCU Rams_NCAAB',
    'UAB Blazers_NCAAB',
    'Dayton Flyers_NCAAB',
    'Oklahoma St Cowboys_NCAAB',
    'Washington St Cougars_NCAAB',
    'Oregon Ducks_NCAAB',
    'Towson Tigers_NCAAB',
    'Belmost Bruins_NCAAB',
    'Fresno St Bulldogs_NCAAB',
    'Syracus Orange_NCAAB'
]

const NCAABteams = [
    'Gonzaga Bulldogs_NCAAB',
    'Arizona Wildcats_NCAAB',
    `Houston Cougars_NCAAB`,
    'Kentucky Wildcats_NCAAB',
    'Baylor Bears_NCAAB',
    'Kansas Jayhawks_NCAAB',
    'Villanova Wildcats_NCAAB',
    'Tennessee Volunteers_NCAAB',
    'Duke Blue Devils_NCAAB',
    'Texas Tech Red Raiders_NCAAB',
    'Auburn Tigers_NCAAB',
    'Purdue Boilermakers_NCAAB',
    'UCLA Bruins_NCAAB',
    'Illinois Fighting Illini_NCAAB',
    'Texas Longhorns_NCAAB',
    'LSU Tigers_NCAAB',
    'Connecticut Huskies_NCAAB',
    'Iowa Hawkeyes_NCAAB',
    `Saint Mary's Gaels_NCAAB`,
    'Ohio St Buckeyes_NCAAB',
    'Wisconsin Badgers_NCAAB',
    'Alabama Crimson Tide_NCAAB',
    'Arkansas Razorbacks_NCAAB',
    'Murray St Racers_NCAAB',
    'Providence Friars_NCAAB',
    'USC Trojans_NCAAB',
    'Colorado St Rams_NCAAB',
    'San Francisco Dons_NCAAB',
    'Boise St Broncos_NCAAB',
    'San Diego St Aztecs_NCAAB',
    'Loyola Ramblers_NCAAB',
    'Seton Hall Pirates_NCAAB',
    'Michigan St Spartans_NCAAB',
    'Marquette Golden Eagles_NCAAB',
    'Iowa St Cyclones_NCAAB',
    'Xavier Musketeers_NCAAB',
    'Michigan Wolverines_NCAAB',
    'North Texas Mean Green_NCAAB',
    'Wyoming Cowboys_NCAAB',
    'North Carolina Tar Heels_NCAAB',
    'Virginia Tech Hokies_NCAAB',
    'Memphis Tigers_NCAAB',
    'Wake Forest Demon Deacons_NCAAB',
    'Indiana Hoosiers_NCAAB',
    'Davidson Wildcats_NCAAB',
    'Notre Dame Fighting Irish_NCAAB',
    'SMU Mustangs_NCAAB',
    'Oklahoma Sooners_NCAAB',
    'TCU Horned Frogs_NCAAB',
    'BYU Cougars_NCAAB',
    'Florida Gators_NCAAB',
    'Mississippi St Bulldogs_NCAAB',
    'VCU Rams_NCAAB',
    'UAB Blazers_NCAAB',
    'Dayton Flyers_NCAAB',
    'Oklahoma St Cowboys_NCAAB',
    'Washington St Cougars_NCAAB',
    'Oregon Ducks_NCAAB',
    'Towson Tigers_NCAAB',
    'Belmost Bruins_NCAAB',
    'Fresno St Bulldogs_NCAAB',
    'Syracus Orange_NCAAB'
]

const NCAABQuery = (input) => {

    const response = NCAABQuieries.filter(name => input == name);

    if (response[0]){
        return true;
    } else {
        return false;
    }
}

export { 
    NCAABteams,
    NCAABQuieries, 
    NCAABQuery} ;