import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ONE_USER } from '../../utils/queries';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Auth from '../../utils/auth';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import FriendList from '../FriendList';
import MiniTickets from '../MiniTickets';
import CryptoInteraction from '../CryptoInteraction';
import CheckBets from '../CheckBets';
import PrintBets from '../PrintBets';

const Dashboard = ({ cryptoPrice }) => {

    //Force the document to have a black background. CSS was not working for this - so used JS
    document.body.style.backgroundColor = "black";

    //Use States Functions
    //Life changes the type of Record Being Shown (Life, Yearly, Monthly)
    const [timeFrame, setTimeFrame] = useState('Life');
    //This will open and render the Div showing the Friendslist
    const [friendList, setFriendList] = useState('');
    //This to change what Bet type should be rendered (wins, losses, or ties)
    const [typeOfBet, setTypeOfBet] = useState('Won');


    //Retrieving User ID to query User Data and storeing that into an object to manipulate
    const { userId } = useParams();
    const { loading, data, refetch } = useQuery(GET_ONE_USER, {
        variables: { id: userId }
    });


    //If Querying Data is still Loading then Render Awaiting Data.
    if (loading) {
        return (
            <div className="propText">Awaiting Data</div>
        )
    }

    //Setting Up Variables To Store Information
    //Storing the User Data into a static variables.
    let user = data?.user || ''
    let wins = user.wonBets?.length || 0
    let losses = user.lostBets?.length || 0
    let ties = user.tieBets?.length || 0
    let totalBets = wins + losses + ties
    let winPercentage = Math.floor(wins / totalBets * 100);
    let $wins = 0;
    let $losses = 0;


    //Console Log Any Data
    console.log(user);

    //If No Games Have Been Played, then we want the Winning Percentage to be 0 & Not NAN
    if (isNaN(winPercentage)) { winPercentage = 0 };

    //For Each Winning Bet Add the winning Amount together
    user.wonBets.forEach(bet => {
        $wins += bet.toWinAmount
    })

    //For Each Losing Bet Add the Losing Amount together
    user.lostBets.forEach(bet => {
        $losses += bet.riskAmount
    })

    //Function Changes whether to Render Life, Year or Monthly Data Set
    const handleTimeFrame = (input) => {


        if (input === 'Life') {
            setTimeFrame('Life')
        }

        if (input === 'Year') {
            setTimeFrame('Year')
        }

        if (input === 'Month') {
            setTimeFrame('Month')
        }

    }

    //Handles what bet type will render (wins, losses, or ties)
    const handleBetHistory = (type) => {

        if(type === "Won"){
            setTypeOfBet("Won");
        };

        if(type === "Lost"){
            setTypeOfBet("Lost");
        }

        if(type === "Tie"){
            setTypeOfBet("Tie")
        }
    }

    //On Click Function to Show the Friends List
    const showFriendList = () => {
        setFriendList('t')
    }

    //On Click Function to Close the Friends List
    const closeFriendList = () => {
        setFriendList('')
    }

    //On Click Function to logout the User
    const logout = () => {

        Auth.logout();

    }

    return (

        <div>
            {/* Header Div */}
            <header className="dashboardHeader titleLowerBorder">
                <div className="dashboardLink">
                    <Link to={`/search/${Auth.getContractor().data._id}`} className="navStyle">{`<--Search`}</Link>
                </div>
                <div className="dashboardLink">
                    <Link className="navStyle" to="#" onClick={showFriendList}>Friend List</Link>
                    <Link className="navStyle" to={`/settings/${Auth.getContractor().data._id}`}>Settings</Link>
                    <Link className="navStyle" to="#" onClick={() => logout()}>Logout</Link>
                </div>
            </header>

            {/* Div Containing Username, Tier, Account Value, Win/Losses/Ties Records */}
            <div className="container-fluid paddingBtm">

                <div className="row">
                    <div className="row dashboardWelcome">
                        <div className="col-md-6 m-2">
                            <p className="loginTitle1 m-0"><b>Hello, </b><span className="textGold">{user.firstName}</span></p>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-12">
                                <div className="row m-0">
                                    <div className="col-md-12 bggray titleBorder">
                                        <br />
                                        <div className="row statisticsText">Tier: Bronze 🥉</div>
                                        <div className="row statisticsText">Current Account Value: {`${user.accountValue.toFixed(3)} SOL`}</div>
                                        <div className="row statisticsText">Current Price of 1 Sol: ${cryptoPrice}</div>
                                        <br />
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row m-0">
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <p className="selectTimeText titleLowerBorder"><b>Select a timeframe</b></p>
                                                    </div>
                                                </div>
                                                <div className="row m-0">
                                                    <div className="d-flex justify-content-center col-12">
                                                        {timeFrame === 'Life' ? (<Link className="onLink" to="#" onClick={() => handleTimeFrame('Life')}>Life</Link>) : (<Link className="offLink" to="#" onClick={() => handleTimeFrame('Life')}>Life</Link>)}
                                                        {timeFrame === 'Year' ? (<Link className="onLink" to="#" onClick={() => handleTimeFrame('Year')}>Year</Link>) : (<Link className="offLink" to="#" onClick={() => handleTimeFrame('Year')}>Year</Link>)}
                                                        {timeFrame === 'Month' ? (<Link className="onLink" to="#" onClick={() => handleTimeFrame('Month')}>Month</Link>) : (<Link className="offLink" to="#" onClick={() => handleTimeFrame('Month')}>Month</Link>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="row statisticsText"><div className="col-12"><p className="m-0"><u className="goldT">{timeFrame} Statistics</u></p></div></div>
                                                <div className="row statisticsText"><div className="col-12"><p className="m-0">Record: {wins}-{losses}-{ties}</p></div></div>
                                                <div className="row statisticsText">
                                                    <div className="d-flex col-12">
                                                        <p className="m-0">Total Winnings:</p>
                                                        <p className="m-0 winnings"> ~ +${($wins * cryptoPrice).toFixed(2)}</p>
                                                    </div>
                                                </div>
                                                <div className="row  d-flex statisticsText">
                                                    <div className="d-flex col-12">
                                                        <p className="m-0">Total Losses:</p>
                                                        <p className="m-0 losses">~ -${($losses * cryptoPrice).toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-7 d-flex justify-content-center">
                                                <div className="dashboardWinPercentage m-1"><b>Win Percentage:</b></div>
                                                <div className="m-2" style={{ width: 125, height: 125 }}><CircularProgressbar
                                                    value={winPercentage}
                                                    text={`${winPercentage}%`}
                                                    styles={buildStyles({
                                                        pathColor: 'green',
                                                        textColor: 'green'
                                                    })} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Div to Print All the Current Open Bets */}
                        <div className="row m-0 p-0">
                            <div className="col-12 paddingForOpenBetDiv">
                                <div className="row">
                                    <div className="col-12 openBetTitle">Open Bets</div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-12 titleBorder scrollDiv">
                                        <div className="row">
                                            <div className="col-12">
                                                {user.placedBets.map((placedBet, index) => (
                                                    <MiniTickets user={user} bet={placedBet} key={index} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Div to Print All Past bets - Wins, Losses, Ties */}
                    <div className="col-md-6">
                        <div className="row m-0">
                            <div className="row">
                                <div className="col-12 betHistoryTitle">Bet History</div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    {typeOfBet === 'Won' ? (<Link className="onLink" to="#" onClick={() => handleBetHistory('Won')}>Wins</Link>) : (<Link className="offLink" to="#" onClick={() => handleBetHistory('Won')}>Wins</Link>)}
                                    {typeOfBet === 'Lost' ? (<Link className="onLink" to="#" onClick={() => handleBetHistory('Lost')}>Losses</Link>) : (<Link className="offLink" to="#" onClick={() => handleBetHistory('Lost')}>Losses</Link>)}
                                    {typeOfBet === 'Tie' ? (<Link className="onLink" to="#" onClick={() => handleBetHistory('Tie')}>Tie</Link>) : (<Link className="offLink" to="#" onClick={() => handleBetHistory('Tie')}>Ties</Link>)}
                                </div>
                            </div>
                            <div className="col-md-12 bggray titleBorder scrollDiv">
                                <PrintBets user={user} typeOfBet={typeOfBet} />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className={friendList ? "propText friendNav" : "vHidden"}><FriendList user={user} closeFriendList={closeFriendList} cryptoPrice={cryptoPrice} refetch={refetch} /></div>
            <CheckBets user={user} refetch={refetch} />
            <CryptoInteraction user={user} refetch={refetch} />
        </div>
    )
}

export default Dashboard;