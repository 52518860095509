import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ONE_USER } from '../../utils/queries';

import { useParams } from 'react-router-dom';

import { LOSE_BET, WIN_BET, TIE_BET } from '../../utils/mutations';
import sportsDB from '../../utils/SportsDBAPI';

import moment from 'moment';
import editTieBet from '../../utils/tieBet';
import axios from 'axios';


const { REACT_APP_ODDS_KEY_SPECIFIC  } = process.env || process.env.REACT_APP_ODDS_KEY_SPECIFIC

const Test = () => {

    // Mutations to change account Value and place a passed bet into win, loss, or tie.
    const [loseBet] = useMutation(LOSE_BET);
    const [winBet] = useMutation(WIN_BET);
    const [tieBet] = useMutation(TIE_BET);

    const { userId } = useParams();


    const { data, refetch } = useQuery(GET_ONE_USER, {
        variables: { id: userId }
    });

    const user = data?.user || [];

    const addUnderScore = (input) => {
        let feeder = input.split(' ').join('_');
        return feeder;
    }

    const updateDatabaseTieBet = async (newBet) => {

        let newTicket = [...newBet.ticket];

        try {

            await tieBet({
                variables: {
                    id: newBet._id,
                    toWinAmount: newBet.toWinAmount,
                    ticket: newTicket
                }
            })

            await refetch();
            clickIt();

        } catch (e) {
            console.log(e);
        }

    }

    const testButton = async () => {

        const resp = await axios.get(`https://api.the-odds-api.com/v4/sports/basketball_ncaab/odds/?apiKey=${REACT_APP_ODDS_KEY_SPECIFIC}&regions=us&oddsFormat=american&markets=h2h,spreads,totals`);
        console.log(resp.data);

    }

    const clickIt = async () => {

        let gameResults = [];

        let opponent;
        let forBet;

        // //TESTING DATA
        // const testDate = sliceDate(user.placedBets[0].ticket[0].date)
        // const testGame = addUnderScore(user.placedBets[0].ticket[0].game);
        // const testTeam = user.placedBets[0].ticket[0].team;
        // const testLeague = user.placedBets[0].ticket[0].league;

        // // TESTING URL
        //let gameURL = `https://www.thesportsdb.com/api/v1/json/2/searchfilename.php?e=${testLeague}_${testDate}_${testGame}`;
        // let testURL = 'https://www.thesportsdb.com/api/v1/json/2/searchfilename.php?e=NFL_2021-11-28_Denver_Broncos_vs_Los_Angeles_Chargers';
        // gameResults = await sportsDB(testURL);
        // console.log(gameResults);
        // console.log(testURL);

        // Looping through each bet in the User.placedBets array
        for (let i = 0; i < user.placedBets.length; i++) {

            console.log(user.placedBets[i]);

            //Variable to keep track if the winner has won all the bets in an array. If winCount matches placedBets.length than it will be a winning ticket
            let winCount = 0;

            //Looping through each ticket array with each placeBets Array
            for (let k = 0; k < user.placedBets[i].ticket.length; k++) {

                //Variable to check if an array item has a loss attached to it, or is not ready for the bet. This will break the loop if parlay check = 1, and continue if = 0.
                let parlayCheck = 0;

                if (parlayCheck === 1) {
                    break;
                }

                //Setting variables for the current date & ticket bet date for comparisson
                let gameTimeBuffer = 4 * 3600
                const unixTimeNow = (Date.now() / 1000) + gameTimeBuffer
                const unixOfBet = moment(user.placedBets[i].ticket[k].date).unix()

                // If the Date for any of the tickets is greater than the current date time, the function will exit. 
                if (unixOfBet > unixTimeNow) {
                    console.log("Bet is not ready");
                    parlayCheck = 1;
                    break;
                }

                // Variables to set up the Sports DB API URL
                let dateOfGame = moment.utc(user.placedBets[i].ticket[k].date).format('YYYY-MM-DD');
                let gameURL = addUnderScore(user.placedBets[i].ticket[k].game);
                let leagueURL = addUnderScore(user.placedBets[i].ticket[k].league);

                // VARIABLE TO STORE URL WITH LEAGUE DATA - ENABLES US TO QUERY A GAME OBJECT FROM SPORTS DB
                let URL = `https://www.thesportsdb.com/api/v1/json/2/searchfilename.php?e=${leagueURL}_${dateOfGame}_${gameURL}`;
                console.log(URL);

                // AXIOS CALL TO SPORTSDB TO QUERY DATA
                gameResults = await sportsDB(URL);
                console.log(gameResults);

                if(gameResults[2].matchStatus !== true){
                    console.log("Game is not complete");
                    break;
                }

                // Comparing a bet with Spread
                if (user.placedBets[i].ticket[k].type === "Spread") {

                    console.log("Inside The Spread Operator");

                    // Looping through game Results Object to match which team the ticket bet for
                    for (let j = 0; j < gameResults.length; j++) {

                        // Finding the correct team with the gameResults object to compare against bet
                        if (user.placedBets[i].ticket[k].team === gameResults[j].team) {

                            // Checking to see if ticket spread is positive or negative since functions will have to be different to calculate if the bet is won, lost, or tied
                            if (Math.sign(user.placedBets[i].ticket[k].spread) === 1) {

                                // Logic to compare spread if bettor took a positive spread
                                if (gameResults[j].spread > -(user.placedBets[i].ticket[k].spread)) {

                                    winCount += 1;
                                    console.log(`Game spread was ${gameResults[j].spread}. Win with ${user.placedBets[i].ticket[k].spread}`);
                                    console.log(1);


                                } else if (Math.abs(gameResults[j].spread) === user.placedBets[i].ticket[k].spread) {

                                    console.log(`Game spread was ${gameResults[j].spread}. Push with ${user.placedBets[i].ticket[k].spread}`);
                                    console.log(2);

                                    //Remove the tie bet and return a newBet object to modify Database
                                    let newBet = editTieBet(user.placedBets[i], k);

                                    //Update the database with the new bet. This refetches and restarts clickIt function
                                    updateDatabaseTieBet(newBet);

                                } else {

                                    winCount -= 1;
                                    parlayCheck = 1;
                                    console.log(`Game spread was ${gameResults[j].spread}. Loss with ${user.placedBets[i].ticket[k].spread}`);
                                    console.log(3);
                                    break;

                                }
                            } else {
                                // Logic to compare if bettor took a negative spread
                                if (gameResults[j].team === user.placedBets[i].ticket[k].team) {
                                    if (gameResults[j].spread > Math.abs(user.placedBets[i].ticket[k].spread)) {

                                        winCount += 1;
                                        console.log(` Game spread was ${gameResults[j].spread}. Win with ${user.placedBets[i].ticket[k].spread}`);
                                        console.log(4);

                                    } else if (gameResults[j].spread === Math.abs(user.placedBets[i].ticket[k].spread)) {

                                        console.log(` Game spread was ${gameResults[j].spread}. Push with ${user.placedBets[i].ticket[k].spread}`);
                                        console.log(5);

                                        //Remove the tie bet and return a newBet object to modify Database
                                        let newBet = editTieBet(user.placedBets[i], k);

                                        //Update the database with the new bet. This refetches and restarts clickIt function
                                        updateDatabaseTieBet(newBet);

                                    } else {

                                        winCount -= 1;
                                        parlayCheck = 1;
                                        console.log(`Game spread was ${gameResults[j].spread}. Loss with ${user.placedBets[i].ticket[k].spread}`);
                                        console.log(6);
                                        break;
                                    }
                                }
                            }
                        }
                    }

                }

                // if Money Line

                // Comparing a Straight Up bet or Money Line Bet
                if (user.placedBets[i].ticket[k].type === "Money Line") {

                    console.log("Inside the Money Line Operator");

                    for (let j = 0; j < gameResults.length; j++) {
                        if (user.placedBets[i].ticket[k].team === gameResults[j].team) {

                            if (Math.sign(gameResults[j].spread) === 1) {

                                winCount += 1;
                                console.log("Congratulations the Money Line Bet. You won")
                                console.log(7);

                            } else if (gameResults[j].spread === 0) {

                                console.log("It's a Push!");
                                console.log(8);

                                //Remove the tie bet and return a newBet object to modify Database
                                let newBet = editTieBet(user.placedBets[i], k);

                                //Update the database with the new bet. This refetches and restarts clickIt function
                                updateDatabaseTieBet(newBet);

                            } else {

                                winCount -= 1
                                parlayCheck = 1;
                                console.log("You lost the money line Bet")
                                console.log(9)
                                break;

                            }
                        }
                    }
                }

                // Logic to compare If Over won, loss, or push
                if (user.placedBets[i].ticket[k].type === "Over") {

                    console.log("Inside the OVER operator");

                    if (user.placedBets[i].ticket[k].spread < gameResults[2].totalScore) {

                        winCount += 1;
                        console.log("You won the OVER Bet");
                        console.log(10);

                    } else if (user.placedBets[i].ticket[k].spread === gameResults[2].total) {

                        console.log("Over PUSH")
                        console.log(11);

                        //Remove the tie bet and return a newBet object to modify Database
                        let newBet = editTieBet(user.placedBets[i], k);

                        //Update the database with the new bet. This refetches and restarts clickIt function
                        updateDatabaseTieBet(newBet);

                    } else {

                        winCount -= 1
                        parlayCheck = 1;
                        console.log("You Lost the Over Bet");
                        console.log(12)
                        break;
                    }
                }

                // Logic to compare if Under won, loss, or push
                if (user.placedBets[i].ticket[k].type === "Under") {

                    console.log("Inside the UNDER Operator");

                    if (user.placedBets[i].ticket[k].spread > gameResults[2].totalScore) {

                        winCount += 1;
                        console.log("You won the UNDER Bet");
                        console.log(13);

                    } else if (user.placedBets[i].ticket[k].spread === gameResults[2].total) {

                        console.log("Under PUSH")
                        console.log(14);

                        //Remove the tie bet and return a newBet object to modify Database
                        let newBet = editTieBet(user.placedBets[i], k);

                        //Update the database with the new bet. This refetches and restarts clickIt function
                        updateDatabaseTieBet(newBet);

                    } else {

                        winCount -= 1
                        parlayCheck = 1;
                        console.log("You Lost the UNDER Bet");
                        console.log(15)
                        break;

                    }
                }

            }

            //Get the person who the user is against
            if (user.placedBets[i].between[0].player1[0].email === user.email) {
                opponent = user.placedBets[i].between[0].player2[0].email;
                forBet = true;
            } else {
                opponent = user.placedBets[i].between[0].player1[0].email
                forBet = false;
            }

            //If the user is for the bet then it will check if wincount is equal to ticket length
            //Else the user is against the bet and the opposite will happen
            if (forBet) {

                // If winCount equals the same as ticket length - then the bettor successfully hit their parlay. Else they lost and bet
                // will be sent to the Losing Array
                if (winCount === user.placedBets[i].ticket.length) {

                    try {

                        await winBet({
                            variables: {
                                id: userId,
                                betId: user.placedBets[i]._id,
                                winAmount: user.placedBets[i].toWinAmount

                            },
                        })
                        console.log("Inside the For Bet Winning If Statement");

                    } catch (e) {
                        console.log(e)
                    }
                } else {

                    try {

                        await loseBet({
                            variables: {
                                id: userId,
                                betId: user.placedBets[i]._id,
                                lostAmount: user.placedBets[i].riskAmount
                            }
                        })

                        console.log("Inside the For Bet Losing If Statement");

                    } catch (e) {
                        console.log(e)
                    }

                }
            } else {

                if (winCount !== user.placedBets[i].ticket.length) {

                    try {

                        await winBet({
                            variables: {
                                id: userId,
                                betId: user.placedBets[i]._id,
                                winAmount: user.placedBets[i].toWinAmount

                            },
                        })

                        console.log("Inside the Against Win Bet If Statement")

                    } catch (e) {
                        console.log(e)
                    }

                } else {

                    try {

                        await loseBet({
                            variables: {
                                id: userId,
                                betId: user.placedBets[i]._id,
                                lostAmount: user.placedBets[i].riskAmount
                            }
                        })

                        console.log("Inside the Against Lose Bet Statement");

                    } catch (e) {
                        console.log(e)
                    }

                }
            }
        }
        await refetch();
    }

    // //Timeout functionality if I wanted to check the bets every 5 seconds.
    // let timerId = setInterval(() => clickIt, 2000);

    // setTimeout(() => { clearInterval(timerId) }, 4000);

    // repeat with the interval of 2 seconds


    // after 5 seconds stop
    // setTimeout(() => { clickIt() }, 3000);

    return (
        <div className="center">
            {/* <button onClick={clickIt}>Did I win?</button> */}
            <button onClick={testButton}>Test</button>
        </div>

    )
}

export default Test;