import axios from 'axios';

const sportsDB = async (query) => {

    console.log(query);

    let gameObject = {};

    let response = await axios.get(query);
    console.log(response.data.event[0]);
    let homeTeam = response.data.event[0]?.strHomeTeam || 'NA';
    let homeTeamPoints = parseInt(response.data.event[0]?.intHomeScore) || 'NA';
    let awayTeam = response.data.event[0]?.strAwayTeam || 'NA';
    let awayTeamPoints = parseInt(response.data.event[0]?.intAwayScore) || 'NA';
    let matchStatus = response.data.event[0]?.strStatus || 'NA';

    if(matchStatus === "Match Finished" || matchStatus === "FT" || matchStatus === "Football"){
        matchStatus = true;
        console.log("Match is Complete");
    }

    if (homeTeamPoints){

        //Object returned as 0 inthome score can sometimes be returned as NA. Turn it back to 0 here
        if(homeTeamPoints === 'NA'){
            homeTeamPoints = 0;
        }

        //Object returned as 0 intaway score can sometimes be returned as NA. Turn it back to 0 here
        if(awayTeamPoints === 'NA'){
            awayTeamPoints = 0
        }

        gameObject = [
            {
                team: homeTeam,
                homeTeamPoints: homeTeamPoints,
                spread: homeTeamPoints - awayTeamPoints

            },
            {
                team: awayTeam,
                awayTeamPoints: awayTeamPoints,
                spread: awayTeamPoints - homeTeamPoints
            }, 
            {
                team: `${homeTeam} vs ${awayTeam}`,
                totalScore: homeTeamPoints + awayTeamPoints,
                matchStatus: matchStatus
            }
        ]
    }

    // //Test Data for running comparison Functionality
    // let homeTeamPoints = 32
    // let awayTeamPoints = 25

    // gameObject = [
    //     {
    //         team: "Denver Broncos",
    //         homeTeamPoints: homeTeamPoints,
    //         spread: homeTeamPoints - awayTeamPoints

    //     },
    //     {
    //         team: "Los Angeles Chargers",
    //         awayTeamPoints: awayTeamPoints,
    //         spread: awayTeamPoints - homeTeamPoints
    //     }, 
    //     {
    //         team: `Denver Broncos vs Los Angeles Chargers`,
    //         totalScore: homeTeamPoints + awayTeamPoints
    //     }
    // ]
    

    // if (!homeTeam) {
    //     console.log("Event Does Not Exist");
    // }   

    //console.log(response);
    //console.log(gameObject);

    return gameObject;
};

export default sportsDB;