const web3 = require(`@solana/web3.js`);

//Get API Key from .env
const { REACT_APP_PHANTOM_PUB_KEY } = process.env || process.env.REACT_APP_PHANTOM_PUB_KEY

const phantomTransaction = async (encryptedString, sol) => {

    console.log(REACT_APP_PHANTOM_PUB_KEY);

    //Authorize Webpage
    await window.solana.connect();

    //Calculate Sol's into Lamports
    const bettingLamports = sol * 1000000000;

    //Network specifc to live Main Network of Solara
    const network = "https://api.mainnet-beta.solana.com";

    //Initializing connection
    const connection = new web3.Connection(network);

    console.log(encryptedString);

    //Recreate Public Key for Transaction
    const publicKey = new web3.PublicKey(encryptedString);
    const storeKey = new web3.PublicKey(REACT_APP_PHANTOM_PUB_KEY);
    console.log(storeKey);

    //Get the latest Blockhash to use inside a transaction
    let recentBlockhash = await connection.getRecentBlockhash();

    //Creating a new Transaction Instance with blockhash and who will pay transaction fee
    const transaction = new web3.Transaction({
        recentBlockhash: recentBlockhash.blockhash,
        feePayer: publicKey
    });

    //Adding the Program via SystemProgram & from/to public Keys
    transaction.add(
        web3.SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: storeKey,
            lamports: bettingLamports
        })
    );

    console.log(transaction);

    //Signing the Transaction & sending it off
    const { signature } = await window.solana.signAndSendTransaction(transaction);
    console.log(signature);

    //Confirm Transaction was complete
    // const completion = await connection.confirmTransaction(signature);
    // console.log(completion);

    return;
}

export default phantomTransaction;