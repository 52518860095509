import React, { useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import { useMutation } from '@apollo/client';
import Form from 'react-bootstrap/Form'
import Auth from '../../utils/auth';
import { CHALLENGE_BET } from '../../utils/mutations';

import phantomTransaction from '../../utils/walletTransactions';

const bcrypt = require('bcryptjs');

const Challenge = ({ user, ticket, setTicket, closeBetRushOrFriend, between, setBetween, 
                     formState, setFormState, toWin, winAmount, setWinAmount, buttonChange, setButtonChange, 
                     cryptoBetAmount, cryptoWinAmount, handleCancelTicket, refetch}) => {

    const [count, setCount] = useState(0);
    const [edit, setEdit] = useState('')
    const [enemy, setEnemy] = useState({
        email: ''
    })
    const [newWager, setNewWager] = useState({
        newSpread: '',
        newOdds: ''
    });

    let change = count;
    let newTicket = ticket;
    const originalTicket = ticket;

    const [challengeBet] = useMutation(CHALLENGE_BET);

    //Traverse the different Games to edit Information
    const nextGame = (direction) => {

        if (direction === "left") {
            if (count >= 1) {
                change--
                setCount(change)
            }
        }

        if (direction === "right") {
            if (count <= ticket.length) {
                change++
                setCount(change)
            }
        }
    }

    const handleSelect = (e) => {

        let newBetween = [{
            player1: [
                {
                    email: user.email || '',
                    for: true
                }
            ],
            player2: [
                {
                    email: e,
                    for: false
                }
            ]
        }]

        console.log(e);
        setEnemy({
            email: e
        });

        setBetween(newBetween)

        change++;
        setCount(change);
    }

    const handleChange = (event) => {

        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value
        })

        if (name === "betAmount") {
            setWinAmount('');
            setButtonChange('t');
        }
    }

    const handleChangeB = (event) => {
        event.preventDefault()

        const { value, name } = event.target

        setNewWager({
            ...newWager,
            [name]: value
        })
    }

    //Toggle On and Off Edit Form
    const showEdit = () => setEdit('t')
    const closeEdit = (event, i) => {
        event.preventDefault();

        newTicket[i].spread = parseFloat(newWager.newSpread) || ticket[i].spread;
        newTicket[i].odds = parseFloat(newWager.newOdds) || ticket[i].odds;

        setTicket(newTicket);

        setNewWager({
            newSpread: '',
            newOdds: ''
        });
        setEdit('')

    }

    //Submit Challenge Bet
    const submitChallengeBet = async (event) => {
        event.preventDefault();

        const checkPassword = bcrypt.compareSync(formState.password, user.password);

        if (!checkPassword) {
            alert("Failed Password");
            return;
        }
        
        if (parseFloat(formState.betAmount) >= 6) {
            alert(`$5 Maximum bets.`);
            return;
        }

        if(cryptoBetAmount > user.accountValue){
            alert(`Easy buddy. You don't have that kind of coing. ${cryptoBetAmount} exceeds ${user.accountValue} sol.`);
            return;
        }

        if (ticket.length >= 1) {
            try {

                await phantomTransaction(user.cryptoPubKey, cryptoBetAmount);

                await challengeBet({
                    variables: {
                        id: user._id,
                        riskAmount: parseFloat(cryptoBetAmount),
                        toWinAmount: parseFloat(cryptoWinAmount),
                        between: between,
                        ticket: ticket,
                        email: enemy.email
                    },
                })

                setButtonChange('t');
                handleCancelTicket();
                refetch();

            } catch (error) {
                console.log(error)
            }
        } else {
            alert("No Bets selected");
        }
    }

    //Render a game depending on Array Count
    const renderCorrectGame = (counter) => {

        //Render Nothing
        if (counter === 0) {
            return (<></>)
        }

        //Arrays start at 0, so need to move the index back one from counter
        let i = counter - 1

        //Set variables for each to rendered element in the array
        let game = ticket[i]?.game || '';
        let odds = ticket[i]?.odds || '';
        let spread = ticket[i]?.spread || '';
        let team = ticket[i]?.team || '';
        let date = ticket[i]?.date || '';

        //Render each Game in a parlay or single bet
        if (counter > 0 && counter <= ticket.length) {
            return (
                <div className="row m-0 parlayFriendDiv">
                    {!edit ? (
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 parlayFriendTextGame">{game}</div>
                            </div>
                            <div className="row">
                                <div className="col-12 parlayFriendTextTeam">{team} | {spread}</div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {date ? (<Moment className="parlayFriendTextDate" format="M/DD h:mm a">{date}</Moment>) : (<></>)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex">
                                    <Link to="#" className="navStyleNU" onClick={showEdit}>Edit</Link>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="col-12">
                            <div className="row propText">
                                <div className="col-12">
                                    <Form>
                                        <div className="row marginAgainstForm">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 parlayFriendTextGame">{game}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 parlayFriendTextTeam">{team} | {spread}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {date ? (<Moment className="parlayFriendTextDate" format="M/DD h:mm a">{date}</Moment>) : (<></>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label className="newSpreadOddsTitle">New Spread...?</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={spread}
                                                name="newSpread"
                                                value={newWager.newSpread}
                                                onChange={handleChangeB}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label className="newSpreadOddsTitle">New Odds...?</Form.Label>
                                            <Form.Control
                                                type="newOdds"
                                                placeholder={odds}
                                                name="newOdds"
                                                value={newWager.newOdds}
                                                onChange={handleChangeB} />
                                        </Form.Group>
                                        <div className="row">
                                            <div className="col-12">
                                                <Link className="navStyleNU" to="#" onClick={(event) => closeEdit(event, i)}>Update</Link>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )
        }

        //Render Challenge Submit Form
        if (counter === ticket.length + 1) {
            return (
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-6">
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="propText">Enter Bet Amount:</Form.Label>
                                    <Form.Control type="number"
                                        placeholder="Bet $$..."
                                        name="betAmount"
                                        value={formState.betAmount}
                                        onChange={handleChange}
                                        className="inputTextSize" />
                                </Form.Group>
                                {buttonChange ? (
                                    <div className="d-flex justify-content-end">
                                        <Link className="addLinkBtn m-0" to="#" onClick={toWin}>Calculate!</Link>
                                    </div>
                                ) : (
                                    <>
                                        {Auth.loggedIn() ? (
                                            <>
                                                <Form.Label className="propText">Enter Password To Confirm</Form.Label>
                                                <Form.Control type="password"
                                                    placeholder="Password..."
                                                    name="password"
                                                    value={formState.password}
                                                    onChange={handleChange} />
                                                <div className="d-flex justify-content-end">
                                                    <button className="confirmBtn" onClick={submitChallengeBet}>Send Challenge!</button>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="d-flex">
                                                <Link className="navStyle m-0" to="/login">Login</Link>
                                                <p className="propText spaceFromLogin">to place a bet</p>
                                            </div>
                                        )
                                        }
                                    </>
                                )
                                }
                            </Form>
                        </div>
                        <div className="col-6">
                            <div className="row">
                            <div className="col-6">
                                    <div className="propText">
                                        <p className="confirmText1">($$)</p>
                                        <p className="confirmText2">~${formState.betAmount}</p>
                                        <p className="confirmText1">To Win</p>
                                        <p className="confirmText2">~${winAmount}</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="propText">
                                        <p className="confirmText1">(SOL)</p>
                                        <p className="confirmText2">{cryptoBetAmount}</p>
                                        <p className="confirmText1">To Win</p>
                                        <p className="confirmText2">{cryptoWinAmount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="row m-0">
                        <div className="col-10">
                            {enemy.email ? (<p className="challengeAgainstText"><span className="textWhite">Opponent:</span> {enemy.email}</p>) : (<></>)}
                        </div>
                        <div className="col-2">
                            <Link className="navStyleNU d-flex justify-content-end m-0" to="#" onClick={() => closeBetRushOrFriend(originalTicket)}>X</Link>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row">
                <div className="col-1">
                    <Link className="arrowSize d-flex align-items-center justify-content-center m-0" to="#" onClick={() => nextGame("left")}>{`<`}</Link>
                </div>
                <div className="col-10 propText">
                    {count === 0 ? (
                        <>
                            <div className="row chooseOpponentText">Choose Your Opponent: </div>
                            <div className="row">
                                <div className="col-12">
                                    <DropdownButton
                                        title="Select Enemy"
                                        id="dropdown-basic-button"
                                        onSelect={handleSelect}
                                        menuVariant='dark'
                                    >
                                        {user.friends.map((friend, index) => (
                                            <Dropdown.Item key={index} eventKey={friend.email}>{friend.email}</Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>)}
                    {renderCorrectGame(count)}
                </div>
                <div className="col-1">
                    <Link className="arrowSize d-flex align-items-center justify-content-center m-0" to="#" onClick={() => nextGame("right")}>{`>`}</Link>
                </div>
            </div>
            <br />
        </div>
    )
}

export default Challenge;