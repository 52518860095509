import React, { useState } from 'react';
import ParlayFriends from '../ParlayFriends';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ACCEPT_CHALLENGE, DELETE_CHALLENGE, REVERSE_TRANSACTION } from '../../utils/mutations';
import Form from 'react-bootstrap/Form';
import Auth from '../../utils/auth';
import moment from 'moment';
import phantomTransaction from '../../utils/walletTransactions';

const bcrypt = require('bcryptjs');


function MiniTicketsChallenges({ user, friend, bet, side, refetch }) {


    //Use States to help with the bet/ticket object and mutations
    const [buttonChange, setButtonChange] = useState('');
    const [formState, setFormState] = useState({
        betAmount: '',
        password: ''
    });
    const [gameStarted, setGameStart] = useState(false);

    const [acceptChallenge] = useMutation(ACCEPT_CHALLENGE);
    const [deleteChallenge] = useMutation(DELETE_CHALLENGE);
    const [deleteTransaction] = useMutation(REVERSE_TRANSACTION);

    //Variable to help render additional information in the ParlayFriends components

    const password = user.password;

    const makeBet = () => setButtonChange('t');
    const closeBet = () => setButtonChange('');
    console.log(side);

    const handleChange = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value
        })

    }

    //Decline a bet function
    const declineBet = async (betId) => {

        try {
            //Send Money (sols) back to person who sent the bet
            await deleteTransaction({
                variables: {
                    id: friend._id,
                    sol: bet.riskAmount
                }
            })


        } catch (e) {
            console.log(e);
        }

        try {
            //Delete the Challenge bets from user and friend arrays
            await deleteChallenge({
                variables: {
                    userId: user._id,
                    friendId: friend._id,
                    betId: betId
                }
            })

        } catch (e) {
            console.log(e);
        }

        refetch();
    }

    //Place a bet function.
    const placeBet = async (event, betId) => {
        event.preventDefault();

        //Reload the Page function
        const reloadPage = () => {
            setButtonChange(false);
            setGameStart(false);
            refetch();
            return;
        }

        //Check if password is correct. Returns a true or false value
        const checkPassword = bcrypt.compareSync(formState.password, password);

        //If bcrypt returns false. Alert the user and return.
        if (!checkPassword) {
            alert("Failed Password");
            return;
        }

        //Convert Date.now() into Unix Time Stamp
        const unixTimeNow = ((Date.now() / 1000))

        //For loop to check and see if game has already started
        for (let i = 0; i < bet.ticket.length; i++) {

            //Convert Time Stamp Into Unix time
            const unixOfBet = (moment(bet.ticket[i].date).unix())

            //If game has already started...
            if (unixTimeNow > unixOfBet) {

                //Open and render that the game has already started and placing a bet is not allowed.
                setGameStart(true);

                
                try {
                    //Send Money (sols) back to person who sent the bet
                    await deleteTransaction({
                        variables: {
                            id: friend._id,
                            sol: bet.riskAmount
                        }
                    })


                } catch (e) {
                    console.log(e);
                }

                //Delete the Challenge bets from user and friend arrays
                try {

                    await deleteChallenge({
                        variables: {
                            userId: user._id,
                            friendId: friend._id,
                            betId: betId
                        }
                    })

                } catch (e) {
                    console.log(e);
                }

                //function to reset button, bet deleted (rendered info), & refetch data
                setTimeout(reloadPage, 2000);
                return;
            }
        }

        //Determine if user has the funds to place the bet
        if (bet.toWinAmount > user.accountValue) {
            alert(`Easy buddy. You don't have that kind of coin. ${bet.toWinAmount} exceeds ${user.accountValue} sol.`);
            return;
        }

        try {

            //send sols to rush account to place the bet - the toWinAmount is the Against Bets risk
            await phantomTransaction(user.cryptoPubKey, bet.toWinAmount);

            //Accept the challenge
            await acceptChallenge({
                variables: {
                    userId: user._id,
                    friendId: friend._id,
                    betId: betId
                }
            })

            setTimeout(reloadPage, 2000);
            return;

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            {bet.ticket.length < 2 ? (
                <div className="d-flex justify-content-between">
                    <div className="tinyBorder"><b>Single Bet</b></div>
                    <div className={side == "Against" ? ("tinyBorder bg-against") : ("tinyBorder bg-for")}><b>{side}</b></div>
                </div>

            ) : (
                <div className="d-flex justify-content-between">
                    <div className="tinyBorder"><b>Parlay</b></div>
                    <div className={side == "Against" ? ("tinyBorder bg-against") : ("tinyBorder bg-for")}><b>{side}</b></div>
                </div>
            )}
            {bet.ticket.map((ticket, index) => (
                <ParlayFriends ticket={ticket} key={index} />
            ))}
            {side == "For" ? (
                <div className="row m-0 bg-card">
                    <div className="col-4 tinyStyle">{bet.riskAmount}</div>
                    <div className="col-4 tinyStyle">to Win </div>
                    <div className="col-4 tinyStyle">{bet.toWinAmount}</div>
                </div>
            ) : (
                <div className="row m-0 bg-card">
                    <div className="col-4 tinyStyle">{bet.toWinAmount}</div>
                    <div className="col-4 tinyStyle">to Win </div>
                    <div className="col-4 tinyStyle">{bet.riskAmount}</div>
                </div>
            )}

            <div className="row m-0">
                <>
                    {side == "Against" ? (
                        <>
                            <div className="col-12 d-flex justify-content-between">
                                {!gameStarted && <Link className="navStyleNU font16" to="#" onClick={makeBet}><span className="textGreen">Accept✅</span></Link>}
                                {!gameStarted && <Link className="navStyleNU font16" to="#" onClick={() => declineBet(bet._id)}><span className="textRed">Decline❌</span></Link>}
                            </div>
                            {buttonChange ? (
                                <>
                                    <div className="col-12 p-0">
                                        <div className="row m-1">
                                            <div className="col-7 flex-fill p-0">
                                                {/* If User has authorized coinbase allow them to make a bet */}
                                                {user.walletAuthorized ? (
                                                    <>
                                                        {/* If Game has started than render and tell user this has happened and bet will be deleted. */}
                                                        {gameStarted ? (
                                                            <>
                                                                <Form.Label className="textRed font16 m-0 d-flex justify-content-center">
                                                                    Game already Started. Bet to be deleted.
                                                                </Form.Label>
                                                            </>
                                                        ) : (
                                                            <Form>
                                                                <Form.Label className="propText">Enter Password To Confirm</Form.Label>
                                                                <Form.Control type="password"
                                                                    placeholder="Password..."
                                                                    name="password"
                                                                    value={formState.password}
                                                                    onChange={handleChange} />
                                                                <div className="d-flex justify-content-end">
                                                                    <button className="confirmBtn" onClick={(event) => placeBet(event, bet._id)}>Book It!</button>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {/*If User has not synced Wallet direct them to Settings Page*/}
                                                        <div className="d-flex flex-column align-items-center">
                                                            <Link className="navStyle m-0" to={`/settings/${Auth.getContractor().data._id}`}>Authorize Crypto Wallet</Link>
                                                            <p className="propText spaceFromLogin">to place a bet</p>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-5">
                                                <div className="row">
                                                    <div className="col-12 d-flex justify-content-start">
                                                        <Link to="#" className="addLinkBtn" onClick={closeBet}>X</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>)}
                        </>
                    ) : (
                        <></>
                    )}
                </>
            </div>
        </>
    )
}

export default MiniTicketsChallenges;