import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { GET_CRYPTO_PRICE } from '../../utils/mutations';

import SideNav from '../SideNav';
import Homepage from '../pages/Homepage';
import SearchPage from '../pages/SearchPage';
import Dashboard from '../pages/Dashboard';
import Test from '../pages/Test';
import SignUp from '../pages/SignUp';
import Settings from '../pages/Settings';
import Login from '../pages/Login';

import Auth from '../../utils/auth';


function RushV2() {

    const [ inputBetween, setInputBetween ] = useState('')
    const [ cryptoPrice, setCryptoPrice] = useState(0);

    const [updateCryptoPrice] = useMutation(GET_CRYPTO_PRICE)

    // Acquire current Crypto Price (Sol)
    useEffect(async () => {

        const resp = await updateCryptoPrice({
            variables: {
                id: "1"
            }
        })
        const price = resp.data.updateCryptoPrice.solana;
        
        setCryptoPrice(parseFloat(price).toFixed(2));

    }, []);

    return (
        <div>
            <Route exact path='/'>
                <Homepage inputBetween={inputBetween} setInputBetween={setInputBetween} cryptoPrice={cryptoPrice} />
            </Route >
            <Route exact path='/login'>
                <Login />
            </Route>
            <Route exact path='/signup'>
                <SignUp />
            </Route>
            {
            Auth.loggedIn() ? (
             <>
                <Route exact path='/search/:userId'>
                    <div>
                        <SideNav inputBetween={inputBetween} setInputBetween={setInputBetween}/>
                        <SearchPage className="bgColor1" inputBetween={inputBetween} setInputBetween={setInputBetween} cryptoPrice={cryptoPrice} />
                    </div>
                </Route>
                <Route exact path='/test/:userId'>
                    <Test />
                </Route>
                <Route exact path='/dashboard/:userId'>
                    <Dashboard cryptoPrice={cryptoPrice}/>
                </Route>
                <Route exact path ='/settings/:userId'>
                    <Settings />
                </Route>
            </>
            ) : (
            <Route exact path='/search'>
                <div>
                    <SideNav inputBetween={inputBetween} setInputBetween={setInputBetween} />
                    <SearchPage className="bgColor1" inputBetween={inputBetween} setInputBetween={setInputBetween} cryptoPrice={cryptoPrice} />
                </div>
            </Route>
            )
            }
        </div>
    )
}

export default RushV2;

