let EPLQuieries = [
    'Soccer',
    'soccer',
    'Futbol',
    'futbol',
    'EPL',
    'Epl',
    'epl',
    'Arsenal',
    'Aston Villa',
    'Bournemouth',
    'Brighton & Hove Albion',
    'Burnley',
    'Chelsea',
    'Crystal Palace',
    'Everton',
    'Leicester City',
    'Liverpool',
    'Manchester City',
    'Manchester United',
    'Newcastle United',
    'Norwich City',
    'Sheffield United',
    'Southampton',
    'Tottenham Hotspur',
    'Watford',
    'West Ham United',
    'Wolverhampton Wanderers'
]

let EPLteams = [
    'Arsenal',
    'Aston Villa',
    'Bournemouth',
    'Brighton & Hove Albion',
    'Burnley',
    'Chelsea',
    'Crystal Palace',
    'Everton',
    'Leicester City',
    'Liverpool',
    'Manchester City',
    'Manchester United',
    'Newcastle United',
    'Norwich City',
    'Sheffield United',
    'Southampton',
    'Tottenham Hotspur',
    'Watford',
    'West Ham United',
    'Wolverhampton Wanderers'
]

const EPLQuery = (input) => {

    const response = EPLQuieries.filter(name => input == name);

    if (response[0]){
        return true;
    } else {
        return false;
    }
}

export { EPLteams, EPLQuery };