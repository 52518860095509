import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ONE_USER } from '../../utils/queries';

import MiniTickets from '../MiniTickets';
import TeamSearchingSideNav from '../TeamSearchingSideNav';

import { Link } from 'react-router-dom';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import { useParams } from 'react-router-dom';

import Auth from '../../utils/auth';

function MobileSideNav({ closeNav, setInputBetween }) {

    let searchedFromHome = localStorage.getItem('search');


    const [formState, setFormState] = useState({ input: searchedFromHome });

    const { userId } = useParams();


    const { loading, data } = useQuery(GET_ONE_USER, {
        variables: { id: userId }
    });

    const user = data?.user || [];
    console.log(user);

    if (loading) {
        return <div>Loading...</div>
    }

    const handleChange = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value
        })

    }

    const onKeyDown = (event) => {

        if (event.key === 'Enter') {

            localStorage.setItem('search', formState.input);
            setInputBetween(localStorage.getItem('search'));
            closeNav();
        }
    }

    const go = async (event) => {

        localStorage.setItem('search', formState.input);
        setInputBetween(localStorage.getItem('search'));
        closeNav();
    }

    const goBack = () => {
        window.location.replace('/')
    }

    const logout = () => {

        Auth.logout();

    }

    return (
        <>

            <div className="">
                <div className="">
                    <div className="d-flex justify-content-between">
                        <Link className="navStyleSideNav" to="/">Homepage</Link>
                        {Auth.loggedIn() ? (<Link className="navStyleSideNav" to="#" onClick={() => logout()}>Logout</Link>) : (<></>)}
                        <Link className="navStyleNU" to="#" onClick={closeNav}>X</Link>
                    </div>
                    <div>
                        <p className="searchText">Search</p>
                        <InputGroup className="">
                            <FormControl
                                placeholder="Enter A Sport..."
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name='input'
                                value={formState.input}
                                onChange={handleChange}
                                onKeyDown={onKeyDown}
                            />
                            <button id="basic-addon1" className="searchBarTextNav" onClick={go}>Search</button>
                        </InputGroup>
                        {formState.input ? (
                            <TeamSearchingSideNav input={formState.input} setFormState={setFormState} />
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="hideText m-0">T</div>
                    {Auth.loggedIn() ? (
                        <div className="d-flex flex-column">
                            <Link to={`/dashboard/${Auth.getContractor().data._id}`} className="navStyleNU">Dashboard</Link>
                            <Link to={`/settings/${Auth.getContractor().data._id}`} className="navStyleNU">Settings</Link>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="hideText">T</div>
                    <div className="accountValue">Account Value: {user.accountValue} sol</div>
                    <div className="hideText">T</div>
                    <div className="titleLowerBorder">
                        <h2 className="betsTitle m-0">Tickets</h2>
                    </div>
                    {Auth.loggedIn() ? (
                        <div className="propText">
                            <div className="container-fluid p-0">
                                {user.placedBets.map((placedBet, index) => (
                                    <MiniTickets user={user} bet={placedBet} key={index} />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex">
                            <Link className="navStyle m-0" to="/login">Login</Link>
                            <p className="propText spaceFromLogin">to view Tickets</p>
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export default MobileSideNav;