import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';

import { useMutation } from '@apollo/client';
import { LOGIN_USER } from '../../utils/mutations';

import { Link } from 'react-router-dom';

import Auth from '../../utils/auth';

function Login() {

    //Formstate to Handle Email and Password type Changes
    const [formState, setFormState] = useState({
        email: '',
        password: ''
    })
    const [failedPassword, setFailedPassword] = useState(false);

    //Mutation to Log User in via typeDefs & Resolvers
    const [login, { error }] = useMutation(LOGIN_USER);

    //Handle Form Input Change
    const handleChange = (event) => {
        event.preventDefault()

        const { value, name } = event.target

        setFormState({
            ...formState,
            [name]: value
        })
    }

    //Submit Function to Log the User in
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {

            //Grab the User's Login/token
            const { data } = await login({
                variables: { ...formState },
            });

            //Save the Users token to local storage and reload the page as Logged In
            Auth.login(data.login.token);

        } catch (e) {
            setFailedPassword(true);
            console.error(e)
        }

        //Reset Email and Password Objects to empty strings
        setFormState({
            email: '',
            password: '',
        });
    };

    return (

        <div className="viewPort center backdrop">
            <div className="jumbotron jStyle cardBorder">
                <div className="container-fluid">
                    <div className="row">
                        <div className="d-flex flex-column align-items-center">
                            <div className="d-flex align-items-start m-0 titleBorder">
                                <p className="title1 m-0"><b>Rush</b></p>
                                <p className="title2 m-0"> V2</p>
                            </div>
                            <div className="m-1">
                                <p className="tag-line m-0">Sports Betting Made Simple</p>
                            </div>
                            <div className='d-flex align-self-start searchMargin'>
                                <p className="m-1 goldT"></p>
                                <p className="m-1 grayT"></p>
                                <p className="m-1 grayT"></p>
                            </div>
                            {/* Form to Log In User */}
                            <Form>
                                <div className="d-flex justify-content-center m-2">
                                    <div className="d-flex m-0 titleLowerBorder fitIt">
                                        <p className="loginTitle1 m-0"><b>Log</b></p>
                                        <p className="loginTitle2 m-0">in</p>
                                    </div>
                                </div>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="formTitleText">Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        name="email"
                                        value={formState.email}
                                        onChange={handleChange}
                                    />
                                    <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label className="formTitleText">Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={formState.password}
                                        onChange={handleChange} />
                                    {failedPassword &&
                                        <Form.Label className="textRed font16 m-0">
                                            Password does not match email.
                                        </Form.Label>
                                    }
                                </Form.Group>
                                <div className="row m-0">
                                    <div className="col-3">
                                        <button className="styleBtns" onClick={handleFormSubmit}>Login</button>
                                    </div>
                                    <div className="col-9 d-flex justify-content-end align-items-start">
                                        <Link className="navStyle" to="/signUp">Sign Up?</Link>
                                        <Link className="navStyle" to="/">Back</Link>
                                    </div>
                                </div>
                                <br />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;