//This file is used to convert Live Odds API string into a string that can be read by Sports DB API for when
//The user checks a bet to see if they won or lost

const sportsConverter = (team) => {

    // ----- Start of Soccer Teams -----
    if(team === "Leeds United"){
        return "Leeds";
    };

    if(team === "Wolverhampton Wanderers"){
        return "Wolves";
    };

    if(team === "Washington Football Team"){
        return "Washington Commanders";
    };

    if(team === "Brighton and Hove Albion"){
        return "Brighton";
    };

    if(team === "West Ham United"){
        return "West Ham";
    };

    if(team === "Leicester City"){
        return "Leicester";
    };

    if(team === "Newcastle United"){
        return "Newcastle";
    };

    if(team === "Norwich City"){
        return "Norwich";
    };

    if(team === "Tottenham Hotspur"){
        return "Tottenham";
    };
    // ----- End of Soccer Teams -----

    // ----- Start of College Basketball Teams -----

    if(team === 'Gonzaga Bulldogs'){
        return 'Gonzaga';
    };

    if(team === 'Arizona Wildcats'){
        return 'Arizona';
    };

    if(team === 'Houston Cougars'){
        return 'Houston';
    };

    if(team === 'Kentucky Wildcats'){
        return 'Kentucky';
    };

    if(team === 'Baylor Bears'){
        return 'Baylor';
    };

    if(team === 'Kansas Jayhawks'){
        return 'Kansas';
    };

    if(team === 'Villanova Wildcats'){
        return 'Villanova';
    };

    if(team === 'Tennessee Volunteers'){
        return 'Tennessee';
    };

    if(team === 'Duke Blue Devils'){
        return 'Duke';
    };

    if(team === 'Texas Tech Red Raiders'){
        return 'Texas Tech';
    };

    if(team === 'Auburn Tigers'){
        return 'Auburn';
    };

    if(team === 'Purdue Boilermakers'){
        return 'Purdue';
    };

    if(team === 'UCLA Bruins'){
        return 'UCLA';
    };

    if(team === 'Illinois Fighting Illini'){
        return 'Illinois';
    };

    if(team === 'Texas Longhorns'){
        return 'Texas';
    };

    if(team === 'LSU Tigers'){
        return 'LSU';
    };

    if(team === 'Connecticut Huskies'){
        return 'Connecticut';
    };

    if(team === 'Iowa Hawkeyes'){
        return 'Iowa';
    };

    if(team === "Saint Mary's Gaels"){
        return 'Saint Marys-California';
    };

    if(team === 'Ohio St Buckeyes'){
        return 'Ohio State';
    };

    if(team === 'Wisconsin Badgers'){
        return 'Wisconsin';
    };

    if(team === 'Alabama Crimson Tide'){
        return 'Alabama';
    };

    if(team === 'Arkansas Razorbacks'){
        return 'Arkansas';
    };

    if(team === 'Murray St Racers'){
        return 'Murray State';
    };

    if(team === 'Providence Friars'){
        return 'Providence';
    };

    if(team === 'USC Trojans'){
        return 'USC';
    };

    if(team === 'Colorado St Rams'){
        return 'Colorado State';
    };

    if(team === 'San Francisco Dons'){
        return 'San Francisco';
    };

    if(team === 'Boise St Broncos'){
        return 'Boise State';
    };

    if(team === 'San Diego St Aztecs'){
        return 'San Diego State';
    };

    //Multiple Loyola Teams - will likely be Chicago
    if(team === 'Loyola Ramblers'){
        return 'Loyola-Chicago';
    };

    if(team === 'Seton Hall Pirates'){
        return 'Seton Hall';
    };

    if(team === 'Michigan St Spartans'){
        return 'Michigan State';
    };

    if(team === 'Marquette Golden Eagles'){
        return 'Marquette';
    };

    if(team === 'Iowa St Cyclones'){
        return 'Iowa State';
    };

    if(team === 'Xavier Musketeers'){
        return 'Xavier';
    };

    if(team === 'Michigan Wolverines'){
        return 'Wolverines';
    };

    if(team === 'North Texas Mean Green'){
        return 'North Texas';
    };

    if(team === 'Wyoming Cowboys'){
        return 'Wyoming';
    };

    if(team === 'North Carolina Tar Heels'){
        return 'North Carolina';
    };

    if(team === 'Virginia Tech Hokies'){
        return 'Virginia Tech';
    };

    if(team === 'Memphis Tigers'){
        return 'Memphis';
    };

    if(team === 'Wake Forest Demon Deacons'){
        return 'Wake Forest';
    };

    if(team === 'Indiana Hoosiers'){
        return 'Indiana';
    };

    if(team === 'Davidson Wildcats'){
        return 'Davidson';
    };

    if(team === 'Notre Dame Fighting Irish'){
        return 'Notre Dame';
    };

    if(team === 'SMU Mustangs'){
        return 'SMU';
    };

    if(team === 'Oklahoma Sooners'){
        return 'Oklahoma';
    };

    if(team === 'TCU Horned Frogs'){
        return 'TCU';
    };

    if(team === 'BYU Cougars'){
        return 'BYU';
    };

    if(team === 'Florida Gators'){
        return 'Florida';
    };

    if(team === 'Mississippi St Bulldogs'){
        return 'Mississippi';
    };

    if(team === 'VCU Rams'){
        return 'VCU';
    };

    if(team === 'UAB Blazers'){
        return 'UAB';
    };

    if(team === 'Dayton Flyers'){
        return 'Dayton';
    };

    if(team === 'Oklahoma St Cowboys'){
        return 'Oklahoma';
    };

    if(team === 'Washington St Cougars'){
        return 'Washington State';
    };

    if(team === 'Oregon Ducks'){
        return 'Oregon';
    };

    if(team === 'Towson Tigers'){
        return 'Towson';
    };

    if(team === 'Belmost Bruins'){
        return 'Belmost';
    };

    if(team === 'Fresno St Bulldogs'){
        return 'Fresno State';
    };

    if(team === 'Syracus Orange'){
        return 'Syracus';
    };

    // ----- End of College Basketball Teams

    return team;
}

export default sportsConverter;