import axios from 'axios';

import { NFLQuery } from './SportsArrays/FootballTeams';
import { NBAQuery } from './SportsArrays/BasketBallTeams';
import { EPLQuery } from './SportsArrays/SoccerTeams';
import { MLBQuery } from './SportsArrays/BaseballTeams';
import { NHLQuery } from './SportsArrays/HockeyTeams';
import { NCAABQuery } from './SportsArrays/NCAABTeams';


const { REACT_APP_ODDS_URL } = process.env || process.env.REACT_APP_ODDS_URL

const { REACT_APP_ODDS_KEY_PARAMS  } = process.env || process.env.REACT_APP_ODDS_KEY_PARAMS

const search = async (query) => {

    let input;
    let response;
    
    if(NFLQuery(query)){   
        input = `americanfootball_nfl`;
    }

    if(EPLQuery(query)){
        input = 'soccer_epl';
    }

    if(NBAQuery(query)){
        input = 'basketball_nba';
    }

    if(MLBQuery(query)){
        input = 'baseball_mlb';
    }

    if(NHLQuery(query)){
        input = 'icehockey_nhl';
    }

    if(NCAABQuery(query)){
        input = 'basketball_ncaab'
    }

    // let testURL = `${REACT_APP_ODDS_URL}${input}${REACT_APP_ODDS_KEY_PARAMS}`;

    try {
        response = await axios.get(`${REACT_APP_ODDS_URL}${input}${REACT_APP_ODDS_KEY_PARAMS}`);
    } catch (e){
        console.log(e);
        response = [];
    }
    
    console.log(response);


    return response;
};

export default search;