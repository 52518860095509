import React, { useState } from 'react';

import ParlayFriends from '../ParlayFriends';

import { Link } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';
import { ADD_BET } from '../../utils/mutations';
import { GET_ONE_USER } from '../../utils/queries'
import { useParams } from 'react-router-dom';
import Auth from '../../utils/auth';
import phantomTransaction from '../../utils/walletTransactions';
import moment from 'moment';

import Form from 'react-bootstrap/Form';
const bcrypt = require('bcryptjs');


function MiniTicketsFriends({ friend, bet, cryptoPrice }) {


    //Use States to help with the bet/ticket object and mutations
    const [trailBet, setTrailBet] = useState({ ...bet });
    const [iWantToTrail, setIWantToTrail] = useState('');
    const [buttonChange, setButtonChange] = useState('t');
    const [odds, setOdds] = useState('');
    const [winAmount, setWinAmount] = useState('');
    const [cryptoBetAmount, setCryptoBetAmount] = useState(0)
    const [cryptoWinAmount, setCryptoWinAmount] = useState(0);
    const [ticket, setTicket] = useState([]);
    const [gameStarted, setGameStart] = useState(false);
    const [formState, setFormState] = useState({
        betAmount: '',
        password: ''
    });

    const [addBet] = useMutation(ADD_BET);
    const { userId } = useParams();
    const { loading, data } = useQuery(GET_ONE_USER, {
        variables: { id: userId }
    });

    if (loading) {
        <div>loading</div>
    }

    let user = data?.user || ''

    const [between, setBetween] = useState([{
        player1: [
            {
                email: data?.user.email || '',
                for: true
            }
        ],
        player2: [
            {
                email: 'rushv2',
                for: false
            }
        ]
    }])

    let side;
    let opponent;

    if (bet.between[0].player1[0].email == friend.email) {

        side = "For"
        opponent = bet.between[0].player2[0].email

        if (opponent == 'rushv2') {
            opponent = 'RushV2'
        }

    } else {

        side = "Against"
        opponent = bet.between[0].player1[0].email
    }

    const password = data?.user.password;

    const openBetPlay = () => {

        //Convert Date.now() into Unix Time Stamp
        const unixTimeNow = ((Date.now() / 1000))

        //Check to see if any of the games have already started
        for (let i = 0; i < bet.ticket.length; i++) {
            //Convert Time Stamp Into Unix time
            const unixOfBet = (moment(bet.ticket[i].date).unix())

            //if yes, then render as such on screen and return the function
            //If date now is greater than the time of game start, then game has started
            if (unixTimeNow > unixOfBet) {
                setIWantToTrail(true);
                setGameStart(true);
                return;
            }  
        }

        //Setting variables to determine the odds
        let toWinAmount = trailBet.toWinAmount;
        let riskAmount = trailBet.riskAmount;

        //Set odds
        setOdds(toWinAmount / riskAmount);

        // Removing __typename:ticket from the bet.ticket object so addBet Mutation will work properly
        let mutatedTicket = JSON.stringify(bet.ticket)
        let key = "betId"

        //Loop through and remove typename for each object in the array
        for (let i = 0; i < bet.ticket.length; i++) {
            mutatedTicket = mutatedTicket.replace('"__typename":"Ticket",', '');
        }

        // Parse back into an object that can be read by Graphql
        let parsedTicket = JSON.parse(mutatedTicket);

        //Delete unwanted data in the string - specifically the "betId"
        for (let k = 0; k < parsedTicket.length; k++) {
            delete parsedTicket[k][key]
        }

        console.log(parsedTicket);

        //Set the ticket as the new parsed Array
        setTicket([...parsedTicket]);

        //Open Place A Bet Console
        setIWantToTrail('t')
    }

    //Reset the Trailining object when window is closed
    const closeBetPlay = () => { setIWantToTrail(''); }

    //Handle any input changes inside the Trail Bet Modal (Dollar amount for how much user wants to bet)
    const handleChange = (event) => {
        event.preventDefault();

        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value
        })

        if (name === "betAmount") {
            setWinAmount('');
            setButtonChange('t');
        }

    }

    const toWin = (e) => {
        e.preventDefault();
        console.log(cryptoPrice);

        let dollarAmount = Math.round(formState.betAmount * odds)
        setWinAmount(dollarAmount);

        //Converting $$$ Amount into Litecoin Crypto Price
        setCryptoBetAmount((formState.betAmount / cryptoPrice).toFixed(3));

        //Convering Win Amount into Crypto Litecoin Price
        setCryptoWinAmount((dollarAmount / cryptoPrice).toFixed(3));

        setButtonChange('');

    }

    const placeBet = async (e) => {
        e.preventDefault();

        const checkPassword = bcrypt.compareSync(formState.password, password);

        if (!checkPassword) {
            alert("Failed Password");
            return;
        }

        if (cryptoBetAmount > user.accountValue) {
            alert(`Easy buddy. You don't have that kind of coing. ${cryptoBetAmount} exceeds ${user.accountValue}.`);
            return;
        }

        if (parseFloat(formState.betAmount) >= 6) {
            alert(`$5 Maximum bets.`);
            return;
        }

        if (ticket.length >= 1) {

            await phantomTransaction(user.cryptoPubKey, cryptoBetAmount);

            try {
                await addBet({
                    variables: {
                        id: userId,
                        riskAmount: parseFloat(cryptoBetAmount),
                        toWinAmount: parseFloat(cryptoWinAmount),
                        between,
                        ticket: ticket
                    },
                })

                setButtonChange('t');
                setTicket('')
                window.location.reload();


            } catch (error) {
                console.log(error)
            }
        } else {
            alert("No Bets selected");
        }

    }


    return (
        <>
            {bet.ticket.length < 2 ? (
                <div className="d-flex justify-content-between">
                    <div className="tinyBorder"><b>Single Bet</b></div>
                    <div className={side == "Against" ? ("tinyBorder bg-against") : ("tinyBorder bg-for")}><b>{side}</b></div>
                </div>
            ) : (
                <div className="d-flex justify-content-between">
                    <div className="tinyBorder"><b>Parlay</b></div>
                    <div className={side == "Against" ? ("tinyBorder bg-against") : ("tinyBorder bg-for")}><b>{side}</b></div>
                </div>
            )}
            {bet.ticket.map((ticket, index) => (
                <ParlayFriends ticket={ticket} key={index} />
            ))}
            <div className="row marginBelow">
                {!iWantToTrail ? (
                    <>
                        <div className="col-12 d-flex justify-content-between">
                            <div className="textWhite">vs. {opponent}</div>
                            {opponent === "RushV2" ? (<Link className="navStyle m-0" to="#" onClick={openBetPlay}>Trail?</Link>) : (<></>)}
                        </div>
                    </>
                ) : (
                    <>
                        {/* Check to see if Game has started. If so, render "Game started..." */}
                        {gameStarted ? (
                            <>
                                <div className="d-flex justify-content-end">
                                    <Link to="#" className="addLinkBtn" onClick={closeBetPlay}>X</Link>
                                </div>
                                <div className="deactivateLink d-flex justify-content-center">This Ticket has a game that started. No bets allowed.</div>
                            </>
                        ) : (
                            <>
                                {/* If user wallet is connected, render the ability to place a bet. If not, render a link to authorize crypto wallet */}
                                {user.walletAuthorized ? (
                                    <div className="col-12 p-0">
                                        <div className="row m-1">
                                            <div className="col-6 flex-fill p-0">
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label className="propText">Enter Bet Amount:</Form.Label>
                                                        <Form.Control type="number"
                                                            placeholder="Bet Amount..."
                                                            name="betAmount"
                                                            value={formState.betAmount}
                                                            onChange={handleChange}
                                                            className="inputTextSize" />
                                                    </Form.Group>
                                                    {buttonChange ? (
                                                        <div className="d-flex justify-content-end">
                                                            <Link className="addLinkBtn m-0" to="#" onClick={toWin}>Calculate!</Link>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <Form.Label className="propText">Enter Password To Confirm</Form.Label>
                                                            <Form.Control type="password"
                                                                placeholder="Password..."
                                                                name="password"
                                                                value={formState.password}
                                                                onChange={handleChange} />
                                                            <div className="d-flex justify-content-end">
                                                                <button className="confirmBtn" onClick={placeBet}>Book It!</button>
                                                            </div>
                                                        </>
                                                    )
                                                    }
                                                </Form>
                                            </div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-12 d-flex justify-content-end">
                                                        <Link to="#" className="addLinkBtn" onClick={closeBetPlay}>X</Link>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="propText">
                                                            <p className="confirmText1">Bet ($$)</p>
                                                            <p className="confirmText2">~${formState.betAmount}</p>
                                                            <p className="confirmText1">To Win</p>
                                                            <p className="confirmText2">~${winAmount}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="propText">
                                                            <p className="confirmText1">Bet (SOL)</p>
                                                            <p className="confirmText2">{cryptoBetAmount}</p>
                                                            <p className="confirmText1">To Win</p>
                                                            <p className="confirmText2">{cryptoWinAmount}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {/*If User has not synced Wallet direct them to Settings Page*/}
                                        <div className="d-flex justify-content-center">
                                            <Link className="navStyle m-0" to={`/settings/${Auth.getContractor().data._id}`}>Authorize Crypto Wallet</Link>
                                            <p className="propText spaceFromLogin">to place a bet</p>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default MiniTicketsFriends;