import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';

import { Link } from 'react-router-dom';

function Ticket({ ticket, setTicket, handleShowTicket }) {

    // Delete a Bet Object from the Ticket Array when the delete button is clicked
    const removeBet = (i) => setTicket(ticket.filter((bet, index) => index !== i));

    return (

        <>
            {ticket.map((bet, index) => (
                <div className="container-fluid m-0 p-0" key={index}>
                    <div className="d-flex row m-0">
                        <div className="col-1 deleteBtnFlex">
                            <Link className="deleteLink" to="#" onClick={() => removeBet(index)}><b>X</b></Link>
                        </div>
                        <div className="col-2 currentTicket">{bet.type}</div>
                        <div className="col-5 currentTicket">{bet.team}</div>
                        <div className="col-2 currentTicket">{bet.spread}</div>
                        <div className="col-2 currentTicket">{bet.odds}</div>
                    </div>
                </div>
            ))}
            <div>
                <button className="confirmBtn" onClick={handleShowTicket}>Confirm Ticket</button>
            </div>
        </>

    )
}

export default Ticket;